import { Row, Col, Input, Button, notification, Form, Modal } from 'antd';
import { createFromIconfontCN, TeamOutlined, MobileOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import './brand.less';
import api from 'src/services/api';
import { useNavigate } from 'react-router-dom';
import { commErrorHandle } from '@/utils';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1753518_q77wlfezi5f.js',//自定义cdn
})

const Brand = () => {

    // const [contact, setContact] = useState<{ dataType: number, name?: string, tel?: string }>({ dataType: 1 });
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    /**
     * 注册
     * @param types 
     */
    const register = async (types: any) => {
        let data = { t: types };
        const res = await api.brand.add(data);
        if (res.success) {
            modal.confirm({
                title: '成功'
                , content: (<><p>提交成功，平台将在一个至三个工作日内与您联系，请保持手机信号畅通！</p><p>是否跳转个人中心查看进度？</p></>)
                , okText: '确定'
                , cancelText: '取消'
                , onOk: () => navigate('/user/business/brand')
            })
        } else {
            commErrorHandle(res);
        }
    }
    /**
     * 联系
     * @param values 
     */
    const contactSub = async (values: any) => {
        const res = await api.contract.add({ ...values, dataType: 1 });
        if (res.success) {
            modal.success({
                title: '成功'
                , content: (<p>提交成功，平台将在一个至三个工作日内与您联系，请保持手机信号畅通！</p>)
                , okText: '确定'
            })
        } else {
            notification.error({
                message: res.message,
            });
        }
    };

    return (
        <>
            {/* 首屏背景 */}
            <Row className="brand-top-box" justify='center'>
                <Col xxl={16} xs={24} className="brand-top-box-title">
                    <h2>创易鸟知识产权<img src={"/img/brand/r.png"} alt="" /></h2>
                    <p>让您注册商标省心省力</p>
                </Col>
                <Col lg={24} xxl={16} xs={0} className='ctn-box'>
                    <Row gutter={[0, 8]} className="brand-top-box-div">
                        <Col span={24}>
                            <Form form={form} name="user_contact" onFinish={contactSub} autoComplete="off">
                                <Form.Item>
                                    <Input.Group compact size='large'>
                                        <Form.Item noStyle name="name" rules={[{ required: true, message: '请输入您的名字!' }]}>
                                            <Input placeholder="请输入您的名字" addonBefore={<TeamOutlined />} style={{ width: '30%' }} />
                                        </Form.Item>
                                        <Form.Item noStyle name="tel"
                                            rules={[{ required: true, message: '请输入您的手机号码!' }, { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码!' }]}
                                        >
                                            <Input placeholder="请输入您的手机号码" addonBefore={<MobileOutlined />} style={{ width: '35%' }} />
                                        </Form.Item>
                                        <Form.Item noStyle>
                                            <Button type="primary" htmlType="submit" style={{ width: '20%' }} size="large">咨询注册方案</Button>
                                        </Form.Item>
                                        <Button type='link' href='http://wpa.qq.com/msgrd?v=3&uin=3181454981&site=qq&menu=yes' size='large' style={{ width: '15%' }} rel='noreferrer nofollow' target='_blank'>在线咨询</Button>
                                    </Input.Group>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={8} className="brand-top-box-div-li">
                            <p className="common-elip"><TeamOutlined />用户 a******1 通过创易鸟获得商标:XXXXX</p>
                        </Col>
                        <Col span={8} className="brand-top-box-div-li">
                            <p className="common-elip"><TeamOutlined />用户 a******1 通过创易鸟获得商标:MINGHUE</p>
                        </Col>
                        <Col span={8} className="brand-top-box-div-li">
                            <p className="common-elip"><TeamOutlined />用户 a******2221 通过创易鸟获得商标:创意鸟</p>
                        </Col>
                        <Col span={2} className="brand-top-box-div-span"><p>签署合同</p></Col>
                        <Col span={2} className="brand-top-box-div-span"><p>担保交易</p></Col>
                        <Col span={2} className="brand-top-box-div-span"><p>提供证件</p></Col>
                        <Col span={2} className="brand-top-box-div-span"><p>极速退款</p></Col>
                        <Col span={2} className="brand-top-box-div-span"><p>支持线下</p></Col>
                        <Col span={2} className="brand-top-box-div-span"><p>确认后放款</p></Col>
                        <Col span={10} className="brand-top-box-div-span">
                            <p><img src="/img/brand/icon-1.png" alt="" />国家商标局备案机构，安全保障</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* 商标注册 */}
            <Row className="brand-mark-box ctn-box" justify='space-evenly'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>商标注册</h2>
                    <p>TRADEMARK REGISTRATION</p>
                </Col>
                <Col xxl={16} xs={24}>
                    <Row justify='space-evenly' gutter={[0, 16]}>
                        <Col xl={6} xs={20} md={7} className="brand-mark-item">
                            <p className='brand-mark-p'><span>￥</span>580.00<span>/个</span></p>
                            <div className="brand-mark-t">普通注册</div>
                            <div className="brand-mark-b">
                                <p><CheckCircleTwoTone />免费商标查询</p>
                                <p><CheckCircleTwoTone />专业商标注册顾问一对一服务</p>
                                <p><CheckCircleTwoTone />三个工作日内递交商标局</p>
                            </div>
                            <Button onClick={() => register(1)} type='primary' size='large'>立即注册</Button>
                        </Col>
                        <Col xl={6} xs={20} md={7} className="brand-mark-item">
                            <p className='brand-mark-p'><span>￥</span>780.00<span>/个</span></p>
                            <div className="brand-mark-t">极速注册</div>
                            <div className="brand-mark-b">
                                <p><CheckCircleTwoTone />免费商标查询</p>
                                <p><CheckCircleTwoTone />专业商标注册顾问一对一服务</p>
                                <p><CheckCircleTwoTone />一个工作日内递交商标局</p>
                            </div>
                            <Button onClick={() => register(2)} type='primary' size='large'>立即注册</Button>
                        </Col>
                        <Col xl={6} xs={20} md={7} className="brand-mark-item">
                            <p className='brand-mark-p'><span>￥</span>???<span>/个</span></p>
                            <div className="brand-mark-t">商标代购</div>
                            <div className="brand-mark-b">
                                <p><CheckCircleTwoTone />商标被注册或被恶意注册</p>
                                <p><CheckCircleTwoTone />专业商标注册顾问一对一服务</p>
                                <p><CheckCircleTwoTone />多渠道、多方式代购</p>
                            </div>
                            <Button onClick={() => register(3)} type='primary' size='large'>立即注册</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* 所需材料 */}
            <Row className="brand-need-box ctn-box" justify='center'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>所需材料</h2>
                    <p>MATERIALS NEEDED</p>
                </Col>
                <Col xxl={16} xs={24} className="brand-need-box-content">
                    <Row justify='center'>
                        <Col xl={{ span: 4, offset: 2 }} xs={12}>
                            <p><img src="./img/brand/need02.png" />个&emsp;&emsp;人</p>
                        </Col>
                        <Col xl={6} xs={12}>
                            <p>1.申请书</p>
                            <p>2.委托书</p>
                            <p>3.清晰商标图样</p>
                            <p>4.身份证复印件</p>
                            <p>5.个体工商户营业执照复印件</p>
                        </Col>
                        <Col xl={4} xs={12}>
                            <p><img src="./img/brand/need01.png" />企业法人</p>
                        </Col>
                        <Col xl={6} xs={12}>
                            <p>1.申请书</p>
                            <p>2.委托书</p>
                            <p>3.清晰商标图样</p>
                            <p>4.营业执照复印件</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* 服务流程 */}
            <Row className="brand-process-box ctn-box" justify='center'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>服务流程</h2>
                    <p>SERVICE PROCESS</p>
                </Col>
                <Col xxl={16} xs={24} className="brand-process-content">
                    <img src="./img/brand/p-b.jpg" alt="" />
                </Col>
            </Row>
            {/* 常见问题 */}
            <Row className="brand-problem-box ctn-box" justify='center'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>常见问题</h2>
                    <p>COMMON PROBLEM</p>
                </Col>
                <Col xxl={16} className="brand-problem-conent">
                    <Row justify='space-evenly'>
                        <Col xl={12} xs={24}>
                            <p className="common-elip"><img src="./img/brand/icon-q.png" />商标分类如何选择？</p>
                            <p><img src="./img/brand/icon-a.png" />商品或服务分类选择是依据国际尼斯分类来进行的，共分为45个大类，1-34类是商品分类，35-45是效劳分类。您可依据本人运营的产品或效劳停止选择。但有的时分，有些行业并没有在分类表中明白列出，而且也无法由一个分类就完整包含进去，这就呈现了跨类别的状况，对这样的行业要特别留意，假如在类别上选择不当，能够形成对商标的维护力度不够，从而无法全面的停止维护。
                            </p>
                        </Col>
                        <Col xl={{ span: 11, offset: 1 }} xs={24}>
                            <p className="common-elip"><img src="./img/brand/icon-q.png" />商标被驳回了应该怎么办？</p>
                            <p>
                                <img src="./img/brand/icon-a.png" />
                                对于商标局作出驳回通知，申请人不要自觉理亏，特别是在以近似为理由予以驳回的情况下，认为无法挽回，就此放弃。应当听取代理机构的分析建议，考量近似结论是否得当、客观，最终决定是选择放弃还是进行复审，从而最大限度的维护自身利益（很多商标最后取得成功都是复审争取来的，审查员作出的驳回决定并非最终决定）。驳回复审环节体现了在法律上充分给予申请人申辩的机会。
                            </p>
                        </Col>
                        <Col xl={12} xs={24}>
                            <p className="common-elip"><img src="./img/brand/icon-q.png" />商标初审公告后就一定能注册成功吗？</p>
                            <p><img src="./img/brand/icon-a.png" />初步审定公告的商标不等于核准注册，也就是说该商标申请人尚未取得该商标的专用权，只是在公告期内无人提出异议或者是提议裁定不成立，由商标局刊登注册公告，该商标才予以核准注册。经初步审定的商标，自公告之日起三个月内，任何人如认为初步审定的商标违反了《商标法》有关规定，均可向商标局提出异议，被异议商标能否拿到商标注册证，需要商标局异议处最终裁决。异议裁定整个时间为2-3年。在此期间，被异议商标没有商标专用权。
                            </p>
                        </Col>
                        <Col xl={{ span: 11, offset: 1 }} xs={24}>
                            <p className="common-elip"><img src="./img/brand/icon-q.png" />我没有公司可以购买商标嘛？</p>
                            <p><img src="./img/brand/icon-a.png" />您好，如果您这边没有公司也是可以购买商标的呢，没有公司我们这边可以帮您办理个体工商执照，这个执照仅限于商标转让注册使用。办理成功以后，您可以直接把商标过户到您的个人名下。
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={16}>
                    <p className="more"><a href="http://wpa.qq.com/msgrd?v=3&uin=3181454981&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>MORE&nbsp;<IconFont type="icon-add" className='more-icon' /></a></p>
                </Col>
            </Row>
            {contextHolder}
        </>
    );
}

export default Brand;