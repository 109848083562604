import React, { useEffect, useState } from 'react';
import { Button, Col, Table, Typography } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import BigNumber from 'bignumber.js';

interface TableParams {
  pagination?: TablePaginationConfig,
  filters?: Record<string, FilterValue>,
  sorter?: SorterResult<ADO.vouch>
}

const { Paragraph } = Typography;


const UserVouch: React.FC = () => {
  const [data, setData] = useState<ADO.vouch[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns: ColumnsType<ADO.vouch> = [
    {
      title: '担保内容',
      dataIndex: 'title',
    },
    {
      title: '售价',
      dataIndex: 'fee',
      render: (value) => value ? '￥' + BigNumber(value).dividedBy(100).toFixed(2) : '-'
    },
    {
      title: '状态',
      dataIndex: 'state',
      render: (value, record) => {
        switch (value) {
          case 0:
            if (record.auditState === 3) {
              return '审核：不通过，原因：' + record.auditFailReason
            } else if (record.auditState === 2) {
              return '审核：通过';
            } else {
              return '等待审核中';
            }
          case 1:
            return '等待购买';
          case 2:
            return '交接中';
          case 3:
            return '完成';
          default:
            return '-'
        }
      }
    },
    {
      title: '添加时间',
      dataIndex: 'timeAdd',
      render: (value) => value.substring(0, 16)
    },
    {
      title: '购买链接',
      dataIndex: 'shopUrl',
      render: (text) => {
        if (text) {
          return <Paragraph
            copyable={{
              tooltips: ['点击复制链接地址', '复制成功'],
              text: window.location.protocol + '//' + window.location.host + '/trade/vouch/' + text
            }}
          >
            <Button type='link' href={'/trade/vouch/' + text} target="_blank" className="hue-blue">点击跳转</Button>
          </Paragraph>;
        } else {
          return '-';
        }
      }
    },
  ];

  const fetchData = () => {
    setLoading(true);
    api.vouch.list({ ...tableParams.pagination }).then(res => {
      setData(res.records);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    })
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<ADO.vouch>,
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <Col span={24}>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />

    </Col>
  );
};

export default UserVouch;