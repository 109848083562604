import { useEffect, useState } from 'react';
import { Row, Col, Pagination, Space, Avatar, Typography } from 'antd';
import API from '../../../utils/API';
import { BASE_URL } from '../../../config/config'
import { Link, NavLink, useParams } from 'react-router-dom';
import { ADO } from 'src/services/api/typings';
const { Title } = Typography;

const NewsList = () => {
    const params = useParams();
    const [items, setItems] = useState<{ records: Array<ADO.news>, pages: number, total: number, current: number }>({ records: [], pages: 0, total: 0, current: 1 });
    const onChangePage = (page: number) => {
        setItems({ ...items, current: page });
    };

    const types = params.t;

    useEffect(() => {
        let data: any = { page: items.current, limit: 10 };
        if (types) {
            data = { ...data, types: types };
        }
        API(BASE_URL.url1 + "/news/list", "get", data, false).then(res => {
            setItems(res);
        });
    }, [items.current, types]);

    const navActive = ({ isActive }: { isActive: boolean }) => {
        return isActive ? "news-this" : ""
    }

    return (
        <>
            <Row>
                <Col sm={24} xs={0} className="news-nav">
                    <Space size={0}>
                        <Link to="/news" className={types ? "" : "news-this"}>全部资讯</Link>
                        <NavLink to="/news/t/1" className={navActive}>网站交易</NavLink>
                        <NavLink to="/news/t/2" className={navActive}>企业服务</NavLink>
                        <NavLink to="/news/t/3" className={navActive}>知识产权</NavLink>
                        <NavLink to="/news/t/4" className={navActive}>平台公告</NavLink>
                    </Space>
                </Col>
                {items.records.map(item => (
                    <Col lg={24} xs={24} className="news-item" key={item.id}>
                        <Row>
                            <Col className="news-left" lg={20} xs={24}>
                                <Link to={"/news/" + item.id}>
                                    <Title ellipsis={{ rows: 2, expandable: false }} level={2} >{item.title}</Title>
                                    <p>{item.summary}</p>
                                </Link>
                            </Col>
                            <Col className="news-right" lg={4} xs={0}>
                                {item.img && <Avatar src={BASE_URL.img1 + item.img} size={90} shape='square' draggable={false} />}
                                <p>{item.timeAdd?.substring(0, 10)}</p>
                            </Col>
                        </Row>
                    </Col>
                ))}
                {/* 分页 */}
                <Col lg={24} xs={24} className="news-page">
                    <Pagination current={items.current} onChange={onChangePage} total={items.total} />
                </Col>
            </Row>
        </>
    );
}
export default NewsList;