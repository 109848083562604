import { Row, Col, Card } from 'antd';
import { Link, Route, Routes, } from 'react-router-dom';
import './news.less';
import NewsList from './List';
import NewsInfo from './Info';
import api from 'src/services/api';
import { useEffect, useState } from 'react';
import { ADO } from 'src/services/api/typings';

const News = () => {

    const [hotList, setHotList] = useState<Array<ADO.news>>();

    useEffect(() => {
        api.news.listHot().then(res => {
            if (res.success) {
                setHotList(res.data)
            }
        })
    }, [])


    return (
        <>
            <Row justify='center' className='ctn-box'>
                {/* 您的位置 */}
                <Col xxl={16} xs={24} className="nav-log">
                    <p className="common-elip">
                        <span>您的位置：</span>
                        <Link to="/">创易鸟虚拟资产交易平台</Link>
                        <Link to="/news">&gt;资讯中心</Link>
                    </p>
                </Col>
                {/* 资讯内容 */}
                <Col xxl={16} xs={24} className="news-content">
                    <Row>
                        {/* 左侧资讯 */}
                        <Col lg={19} xs={24} className="news-left-box hue-bg-white">
                            <Routes>
                                <Route index element={<NewsList />} />
                                <Route path='t/:t' element={<NewsList />} />
                                <Route path=':id' element={<NewsInfo />} />
                            </Routes>
                        </Col>
                        {/* 右侧导航 */}
                        <Col lg={5} xs={24} className="news-right-box">
                            <Row gutter={[0, 16]}>
                                <Col lg={24} xs={0} className="news-right-img">
                                    <a href='/brand'><img src="/img/news/ad.669da56.jpg" alt="商标注册，铸品牌发展" /></a>
                                </Col>
                                <Col lg={24} xs={24}>
                                    <Card title="热门资讯" className="news-right-hot">
                                        {hotList?.map(news => (
                                            <Link to={"/news/" + news.id} key={news.id}>{news.title}</Link>
                                        ))}
                                    </Card>
                                </Col>
                                {/* <Col lg={24} xs={24}>
                                    <Card title="热门标签" className="news-right-hot-tag">
                                        <Space wrap={true}>
                                            <Link to="javascript:;">网站交易</Link>
                                            <Link to="javascript:;">担保交易</Link>
                                            <Link to="javascript:;">优惠活动</Link>
                                            <Link to="javascript:;">服务费说明</Link>
                                        </Space>
                                    </Card>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
export default News;