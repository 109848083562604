import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 添加
 * @param params 参数
 */
export async function add(data: any): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/brand", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result, "/brand", false) as ADO.ResultMessage;
            }
        })
}
/**
 * 我的注册列表
 * @param data 
 * @returns 
 */
export async function list(data: { current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.brandRegister>> {
    return requestX("GET", BASE_URL.url1 + "/user/brand/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<ADO.brandRegister>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result, "/brand", false) as ADO.ResultPage<ADO.brandRegister>;
            }
        })
}