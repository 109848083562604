import { BASE_URL } from "src/config/config";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 添加
 * @param params 参数
 */
export async function base(): Promise<ADO.ResultMessageT<ADO.statistic>> {
    return requestX("GET", BASE_URL.url1 + "/user/statistic/base")
        .then(request => request.json());
}