import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { BASE_URL } from 'src/config/config';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import { GlobalSettingType } from 'src/services/enums';
import { siteConvert } from '..';
const SitesTj = () => {

    const [siteList, setSiteList] = useState<Array<ADO.website>>();

    useEffect(() => {
        api.globalSetting.list({ type: GlobalSettingType.WEBSITE_TJ, pageSize: 3 }).then(res => {
            setSiteList(res.records.map(sites => siteConvert(sites)));
        })
    }, [])

    return (
        <>
            {siteList && <Col xxl={16} lg={24} md={0} xs={0} className='site-tj-div'>
                <Row>
                    <Col className="site-tj-left" lg={2} xs={4} style={{ backgroundImage: 'url("/img/sites/icon-tag.png)' }}>
                        <p>今日<br />推荐</p>
                    </Col>
                    <Col className="site-tj-content" lg={18} xs={24}>
                        <Row justify="center" gutter={8}>
                            {siteList.map(site => (
                                <Col lg={8} xs={24} key={site.id}>
                                    <Row className="site-tj-item">
                                        <Col className="site-tj-item-left" lg={6}>
                                            <img src={BASE_URL.img1 + site.mainImg} alt="网站主图" />
                                        </Col>
                                        <Col className="site-tj-item-right" lg={18}>
                                            <p>{site.title}</p>
                                            <p>{site.webType}&ensp;BDR{site.bdr}&ensp;{site.ipNum}</p>
                                            <p className="comm-red">￥{site.fee}</p>
                                        </Col>
                                        <Col className="site-tj-item-btn" lg={24}>
                                            <a href={"/sites/" + site.webNum} className="site-tj-item-a">立即购买</a>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}

                        </Row>
                    </Col>
                    <Col className="site-tj-right" lg={4} xs={24}>
                        <a href="http://wpa.qq.com/msgrd?v=3&uin=3181454981&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>网站求购</a>
                        <a href="http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>在线客服</a>
                        <a href="http://wpa.qq.com/msgrd?v=3&uin=3181454981&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>出售网站</a>
                    </Col>
                </Row>
            </Col>}
        </>
    )
}
export default SitesTj;