import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";


/**
 * 列表
 * @param data 
 * @returns 
 */
export async function list(data: { current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.websiteSell>> {
    return requestX("GET", BASE_URL.url1 + "/user/website/sell/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<ADO.websiteSell>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultPage<ADO.websiteSell>;
            }
        })
}

/**
 * 推荐列表
 * @param id 
 * @returns 
 */
export async function put(websiteSell: ADO.websiteSell): Promise<ADO.ResultMessageT<Array<ADO.websiteSell>>> {
    return requestX("PUT", BASE_URL.url1 + "/user/website/sell", websiteSell, { 'Content-Type': 'application/x-www-form-urlencoded' }).then(request => request.json())
}

/**
 * 删除记录
 * @param id 删除id
 * @returns 
 */
export async function del(id: number): Promise<ADO.ResultMessage> {
    return requestX("DELETE", BASE_URL.url1 + "/user/website/sell/" + id).then(request => request.json())
}

/**
 * 获取记录
 * @param id id
 * @returns 
 */
export async function get(id: number): Promise<ADO.ResultMessage> {
    return requestX("GET", BASE_URL.url1 + "/user/website/sell/" + id).then(request => request.json())
}


