import { BASE_URL } from "@/config/config";
import { userInfoGet } from "@/utils/token";
import { Col, Tabs, TabsProps, Form, Input, Button, Radio, Select, InputNumber, Modal, Popconfirm, Typography, Space, Card, Image, Divider, Spin, Row } from "antd"
import Meta from "antd/lib/card/Meta";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "src/services/api";
import { ADO } from "src/services/api/typings"

const { Paragraph, Text, Title } = Typography;

export default (params: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bankList, setBankList] = useState<Array<ADO.userBank>>([]);
    const [openBankAdd, setOpenBankAdd] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getList();
    }, [])



    //银行卡列表
    const getList = () => {
        api.userBank.listAll().then(res => {
            if (res.data && res.data.length > 0) {
                setBankList(res.data);
            }
        });
    }


    const confirm = () => {
        setOpenBankAdd(false);
        navigate('/user/bank');
    };

    const cancel = () => {
        setOpenBankAdd(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        if (!newOpen) {
            setOpenBankAdd(newOpen);
            return;
        }
        if (bankList.length < 1) {
            setOpenBankAdd(newOpen);
        }
    };

    const formParam = { modalOpen: setIsModalOpen, openBankAdd: openBankAdd, cancel: cancel, confirm: confirm, handleOpenChange: handleOpenChange, bankList: bankList }
    //充值、提现表单组件
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '充值',
            children: <RechargeForm {...formParam} />,
        },
        {
            key: '2',
            label: '提现',
            children: <WithdrawForm {...formParam} />,
        }
    ];



    return (
        <>
            <Col span={24}>
                <Tabs defaultActiveKey='1' items={items} />
            </Col>
            <Col span={24}>
                <Typography>
                    <Title level={4}>注意事项：</Title>
                    <Paragraph>
                        1、为了确保资金安全，只支持本人帐号进行提现，我们将核对收款人姓名。（收款人姓名必须跟平台实名认证一致，否则无法提现）
                    </Paragraph>
                    <Paragraph>
                        2、账户不允许从事无真实交易背景的虚假交易，信用卡套现或洗钱等禁止的交易行为，否则充值款项将不能提现。
                    </Paragraph>
                    <Paragraph>3、针对违规违法行为，创易鸟将视情况采取冻结帐号、冻结余额、报送公安机关等综合措施处理。坚决严厉打击、处理包括但不限于以下行为：</Paragraph>
                    <Paragraph>
                        <Space direction="vertical">
                            <Text type="danger">虚假交易：无真实交易背景的虚假交易；</Text>
                            <Text type="danger">套现：通过自销自买的方式进行虚假交易，从而将支付的(信用卡)交易款提现；</Text>
                            <Text type="danger">诈骗：通过欺诈手段诱导他人充值到自己的创易鸟帐号上，再通过虚假交易将钱款提现；</Text>
                            <Text type="danger">洗钱：通过虚假交易将其他灰色收入洗白后提现；</Text>
                            <Text type="danger">代收款：让他人将做网站开发、卖号码等其他业务的收入充值到创易鸟帐号中，再通过虚假交易将钱款提现。</Text>
                        </Space>
                    </Paragraph>
                </Typography>
                <Modal title="成功" open={isModalOpen} onOk={() => { setIsModalOpen(false); navigate('/user/wallet', { state: { recharge: true } }); }} onCancel={() => setIsModalOpen(false)} okText='确定' cancelText='取消' >
                    <p>提交成功，平台将在<span className="hue-main">一个工作日内</span>完成审核。是否跳转查看提现记录？</p>
                </Modal>
            </Col>
        </>
    )
}
// 充值
const RechargeForm = (params: { modalOpen: any, openBankAdd: boolean, cancel: any, confirm: any, handleOpenChange: any, bankList: Array<ADO.userBank> }) => {

    const [payInfo, setPayInfo] = useState<{ [key: string]: ADO.collect }>();
    const [payType, setPayType] = useState<number>(1);
    const [payLoad, setPayLoad] = useState<boolean>(true);
    const [payQrCode, setPayQrCode] = useState<string>("pay/bank.png");
    const [form] = Form.useForm();

    useEffect(() => {
        getPayInfo();
    }, [])

    useEffect(() => {
        const pre = payType === 3 ? BASE_URL.img2 : BASE_URL.img1;
        if (payInfo) {
            switch (payType) {
                case 1:
                    payInfo.ali.imgUrl && setPayQrCode(pre + payInfo.ali.imgUrl);
                    break;
                case 2:
                    payInfo.wx.imgUrl && setPayQrCode(pre + payInfo.wx.imgUrl);
                    break;
                case 3:
                    setPayQrCode(pre + "pay/bank.png");
                    break;
            }
        }
    }, [payType])

    const onFinish = (value: ADO.userRecharge) => {
        value = { ...value, money: BigNumber(value.money).multipliedBy(100).toNumber() };
        if (payInfo) {
            switch (payType) {
                case 1:
                    value.collectId = payInfo.ali.id;
                    break;
                case 2:
                    value.collectId = payInfo.wx.id;
                    break;
                case 3:
                    value.collectId = payInfo.bank.id;
                    break;
            }
        }
        api.userRecharge.recharge(value).then(res => {
            if (res.success) {
                params.modalOpen(true);
                form.resetFields();
            }
        })
    }

    const formChange = (changedValues: any) => {
        if (changedValues && changedValues.payType) {
            setPayType(changedValues.payType);
        }
    }

    //支付链接
    const getPayInfo = () => {
        api.collect.get({ payType: payType }).then(res => {
            if (res.success) {
                if (res.data) {
                    const pre = payType === 3 ? BASE_URL.img2 : BASE_URL.img1;
                    res.data.ali.imgUrl && setPayQrCode(pre + res.data.ali.imgUrl);
                    setPayInfo(res.data);
                    setPayLoad(false);
                }
            }
        })
    }

    return (
        <Row>
            <Col xs={24} sm={24} xl={14} xxl={14}>
                <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    style={{ maxWidth: 700 }}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    initialValues={{ payType: 1 }}
                    onValuesChange={(changedValues) => formChange(changedValues)}
                >
                    <Form.Item
                        label="充值金额"
                        name="money"
                        rules={[{ required: true, message: '充值金额不能为空!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} step="500" addonBefore="￥" precision={2} placeholder="0.01" />
                    </Form.Item>
                    <Form.Item label="支付方式" name="payType" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={1}>支付宝</Radio>
                            <Radio value={2}>微信</Radio>
                            <Popconfirm
                                title="您还未绑定银行卡，是否前往添加？"
                                open={params.openBankAdd}
                                onOpenChange={params.handleOpenChange}
                                onConfirm={params.confirm}
                                onCancel={params.cancel}
                                okText="是"
                                cancelText="否"
                            >
                                <Radio value={3}>银行卡</Radio>
                            </Popconfirm>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.payType !== curValues.payType} noStyle>
                        {({ getFieldValue }) =>
                            getFieldValue('payType') === 3 ? (
                                <Form.Item name="userBankId" label="支付账户" rules={[{ required: true, message: '银行卡不能为空!' }]} >
                                    <Select
                                        options={(params.bankList || []).map(bank => (
                                            { label: bank.bankName + '    ' + bank.accountNum, value: bank.id }
                                        ))}
                                    />

                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.payType !== curValues.payType} noStyle>
                        {({ getFieldValue }) => {
                            let payType = getFieldValue('payType');
                            if (!payType) {
                                payType = 1;
                            }
                            return (payType != 3 ? (
                                <Form.Item
                                    label="支付账户"
                                    name="account"
                                    rules={[{ required: true, message: '账户名称不能为空!' }]}
                                >
                                    <Input placeholder={payType === 1 ? '请输入您的支付宝账户名称' : '请输入您的微信账号'} />
                                </Form.Item>
                            ) : null)
                        }

                        }
                    </Form.Item>
                    <Form.Item wrapperCol={{ xs: { span: 24 }, lg: { offset: 4, span: 16 } }}>
                        <Button type="primary" htmlType="submit"> 提交 </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24} sm={24} xl={{ span: 9, offset: 1 }} xxl={{ span: 8, offset: 1 }}>
                <Card style={{ display: "inline-block" }}>
                    <Spin spinning={payLoad}>
                        <Image src={payQrCode} width={260} preview={false} />
                        {payType === 3 && <Meta title={<><p>收款银行：{payInfo && payInfo.bank.bankName}</p><p>收款账号：{payInfo && payInfo.bank.account}</p><p>收款人：{payInfo && payInfo.bank.name}</p></>} description="请仔细核实收款信息！" />}
                    </Spin>
                    {payType != 3 && <><Divider /><Meta title="收款码" description="请扫描二维码付款" /></>}
                </Card>
            </Col>
        </Row>
    )
}
// 提现
const WithdrawForm = (params: { modalOpen: any, openBankAdd: boolean, cancel: any, confirm: any, handleOpenChange: any, bankList: Array<ADO.userBank> }) => {

    const [form] = Form.useForm();
    const userInfo = userInfoGet();

    const onFinish = (value: ADO.userRecharge) => {
        value = { ...value, money: BigNumber(value.money).multipliedBy(100).toNumber() };
        api.userRecharge.withdraw(value).then(res => {
            if (res.success) {
                params.modalOpen(true);
                form.resetFields();
            }
        })
    }

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                style={{ maxWidth: 600 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                initialValues={{ payType: 1 }}
            >
                <Form.Item
                    label="提现金额"
                    name="money"
                    rules={[{ required: true, message: '提现金额不能为空!' }]}
                >
                    <InputNumber style={{ width: '100%' }} step="500" addonBefore="￥" precision={2} placeholder="0.01" max={BigNumber((userInfo?.balance ? userInfo.balance : 0)).dividedBy(100).toFixed(2)} />
                </Form.Item>
                <Form.Item label="提现方式" name="payType" rules={[{ required: true }]}>
                    <Radio.Group>
                        <Radio value={1}>支付宝</Radio>
                        <Radio value={2}>微信</Radio>
                        <Popconfirm
                            title="您还未绑定银行卡，是否前往添加？"
                            open={params.openBankAdd}
                            onOpenChange={params.handleOpenChange}
                            onConfirm={params.confirm}
                            onCancel={params.cancel}
                            okText="是"
                            cancelText="否"
                        >
                            <Radio value={3}>银行卡</Radio>
                        </Popconfirm>
                    </Radio.Group>
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.payType !== curValues.payType} noStyle>
                    {({ getFieldValue }) =>
                        getFieldValue('payType') === 3 ? (
                            <Form.Item name="userBankId" label="银行卡" rules={[{ required: true, message: '银行卡不能为空!' }]} >
                                <Select
                                    options={(params.bankList || []).map(bank => (
                                        { label: bank.bankName + '    ' + bank.accountNum, value: bank.id }
                                    ))}
                                />

                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.payType !== curValues.payType} noStyle>
                    {({ getFieldValue }) => {
                        let payType = getFieldValue('payType');
                        if (!payType) {
                            payType = 1;
                        }
                        return (payType != 3 ? (
                            <>
                                <Form.Item
                                    label="账户名称"
                                    name="accountPre"
                                    rules={[{ required: true, message: '账户名不能为空!' }]}
                                >
                                    <Input placeholder={payType === 1 ? '请输入您的支付宝账户名称' : '请输入您的微信账号'} />
                                </Form.Item>
                                <Form.Item
                                    label="确认账户"
                                    name="account"
                                    dependencies={['accountPre']}
                                    rules={[{ required: true, message: '账户名不能为空!' }, ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('accountPre') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('两次输入的账户名不一致!'));
                                        },
                                    })]}
                                >
                                    <Input placeholder='请再次输入账户名' />
                                </Form.Item>
                            </>
                        ) : null)
                    }
                    }
                </Form.Item>
                <Form.Item wrapperCol={{ xs: { span: 24 }, lg: { offset: 4, span: 16 } }}>
                    <Button type="primary" htmlType="submit"> 提交 </Button>
                </Form.Item>
            </Form>
        </>
    )
}