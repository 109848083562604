import React from 'react';
import { Row, Col, Carousel } from 'antd';
import { SafetyCertificateOutlined, createFromIconfontCN } from '@ant-design/icons'
import './index.less';
import { Link } from 'react-router-dom';
import Head from '../Head';
import api from 'src/services/api';
import { GlobalSettingType } from 'src/services/enums';
import { ADO } from 'src/services/api/typings';
import { BASE_URL } from 'src/config/config';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1753518_q77wlfezi5f.js',//自定义cdn
})

class Home extends React.Component {

    state = {
        topBoxImg: require("./img/u1.jpg")
        , homeWebSite: []
        , homeNews: []
        , homeAbout: []
    }

    imgUpdate: NodeJS.Timer;

    componentDidMount(): void {
        let imgNum = 1;
        this.imgUpdate = setInterval(() => {
            this.setState({ topBoxImg: require("./img/u" + imgNum + ".jpg") });
            imgNum++;
            if (imgNum > 3) {
                imgNum = 1;
            }
        }, 6000);
        //网站列表
        api.globalSetting.list({ type: GlobalSettingType.HOME_WEBSITE, pageSize: 16 }).then(res => {
            this.setState({ homeWebSite: res.records })
        })
        //资讯
        api.globalSetting.list({ type: GlobalSettingType.HOME_NEWS, pageSize: 5 }).then(res => {
            this.setState({ homeNews: res.records })
        })
        //活动
        api.globalSetting.list({ type: GlobalSettingType.HOME_ABOUT_WE, pageSize: 4 }).then(res => {
            this.setState({ homeAbout: res.records })
        })
    }

    componentWillUnmount(): void {
        clearInterval(this.imgUpdate);
    }

    webSiteDom = (start: number, end: number) => {
        let dom: Array<React.ReactNode> = [];
        if (start > this.state.homeWebSite.length || end > this.state.homeWebSite.length) {
            return undefined;
        }
        const website = this.state.homeWebSite;
        for (start; start < end; start++) {
            const site: ADO.website = website[start];
            dom.push(<Col lg={6} xs={dom.length > 3 ? 0 : 12} key={site.id} >
                <div className='hue-bg-white'>
                    <img src={BASE_URL.img1 + site.mainImg} alt='网站交易图片' />
                    <p className="common-elip home-site-title">{site.title}</p>
                    <p className="home-site-info">{site.webExplain}</p>
                    <Link to={"/sites/" + site.webNum} target="_blank">查&emsp;看</Link>
                </div>
            </Col>)
        }
        return dom;
    }

    render() {
        return (
            <>
                {/* 首屏 */}
                <div className='home-video-box' style={{ backgroundImage: "url(" + this.state.topBoxImg + ")" }}>
                    <video muted loop autoPlay>
                        <source src='/video/home-01.mp4' />
                    </video>
                    <Row className="home-top-box" justify="center" >
                        <Col span={24}>
                            <Head index="true" />
                        </Col>
                        <Col span={24} className='home-top-content'>
                            <Row justify="center">
                                <Col lg={16} xs={0}>
                                    <h1>创易鸟虚拟资产交易平台</h1>
                                </Col>
                                <Col lg={16} xs={24}>
                                    <p>网站交易服务费低至2%</p>
                                    <p>低价注册、代购商标</p>
                                    <p>优质企业服务、专人专办</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div>
                    {/* 交易动态 */}
                    <Row justify='center' className="home-top-trading">
                        <Col xs={24} xxl={16} className='ctn-box'>
                            <Row align="middle">
                                <Col lg={4} xs={0}>
                                    <SafetyCertificateOutlined className='home-top-trading-i' /><span className='common-red'>交易动态</span>
                                </Col>
                                <Col lg={20} xs={24}>
                                    <p className="common-elip"><span>2020-04-01</span>用户XXXX以<span>￥0000.00</span>购买【XXXXXXXXXXXXXX】网站</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* 业务导航 */}
                    <Row justify='center' className='home-bus' >
                        <Col xxl={16} xs={24}>
                            <div className='nav-title'>
                                <h2>业务导航</h2>
                                <p>BUSINESS NAVIGATION</p>
                            </div>
                        </Col>
                        <Col xxl={16} xs={24}>
                            <Row className='home-bus-nav'>
                                <Col xs={12} lg={6}>
                                    <Link to="/sites">
                                        <img src='/img/nav/website.png' />
                                        <div>
                                            <p>网站交易</p>
                                            <p>服务费低至2%</p>
                                        </div>
                                        <IconFont type="icon-right" />
                                    </Link>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Link to="/brand">
                                        <img src='/img/nav/brand.png' />
                                        <div>
                                            <p>商标注册</p>
                                            <p>专业顾问一对一服务</p>
                                        </div>
                                        <IconFont type="icon-right" />
                                    </Link>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Link to="/employer">
                                        <img src='/img/nav/company.png' />
                                        <div>
                                            <p>企业服务</p>
                                            <p>专人专办</p>
                                        </div>
                                        <IconFont type="icon-right" />
                                    </Link>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Link to="/vouch">
                                        <img src='/img/nav/vouch.png' />
                                        <div>
                                            <p>担保交易</p>
                                            <p>平台担保，安全可靠</p>
                                        </div>
                                        <IconFont type="icon-right" />
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* 网站推荐 */}
                    <Row justify='center' className='home-site' align='middle'>
                        <Col xxl={8} xs={18}>
                            <div className='nav-title'>
                                <h2>网站推荐</h2>
                                <p>RECOMMENDED SITES</p>
                            </div>
                        </Col>
                        <Col xxl={8} xs={6}>
                            <Link to="/sites" className="more fl">
                                MORE<IconFont type="icon-add" className='more-icon' />
                            </Link>
                        </Col>
                        <Col xxl={16} xs={24}>
                            <Carousel autoplay autoplaySpeed={2500} className='home-site-list'>
                                <div>
                                    <Row>
                                        {this.webSiteDom(0, 8)}
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        {this.webSiteDom(8, 16)}
                                    </Row>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                    {/* 资讯中心 */}
                    <Row justify='center' className='home-news-box'>
                        <Col xxl={16} xs={24}>
                            <div className='nav-title'>
                                <h2>资讯中心</h2>
                                <p>INFOMATION</p>
                            </div>
                        </Col>
                        <Col xxl={16} xs={24}>
                            <Row align='middle'>
                                <Col lg={12} xs={0} className="home-news-left">
                                    <Row>
                                        <Col lg={24}>
                                            <h3>资讯分类</h3>
                                            <p>
                                                <a href="/news">全部/</a>
                                                <a href="/news/t/1">网站交易/</a>
                                                <a href="/news/t/3">商标注册/</a>
                                                <a href="/news/t/2">企业服务</a>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} xs={24} className="home-news-right">
                                    <Row>
                                        {this.state.homeNews.map((news: ADO.news) => (
                                            <Col lg={24} xs={24} key={news.id}>
                                                <p>{news.timeAdd && news.timeAdd.substring(0, 10)}</p>
                                                <Link to={"/news/" + news.id} target="_blank" className='common-elip-2'>{news.title}</Link>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                <Col lg={24} xs={24}>
                                    <Link to="/news" className="more fl">
                                        MORE<IconFont type="icon-add" className='more-icon' />
                                    </Link>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    {/* 关于我们 */}
                    <Row justify='center' className='home-about' align='middle'>
                        <Col xxl={8} xs={18}>
                            <div className='nav-title'>
                                <h2>关于我们</h2>
                                <p>ABOUT US</p>
                            </div>
                        </Col>
                        <Col xxl={8} xs={6}>
                            <Link to="/about" className="more">
                                MORE<IconFont type="icon-add" className='more-icon' />
                            </Link>
                        </Col>
                        <Col xxl={16}>
                            <Row gutter={[8, 8]}>
                                {this.state.homeAbout.map((item: ADO.news) => (
                                    <Col lg={6} xs={24} key={item.id}>
                                        <Link to={"/news/" + item.id} className='home-about-content'>
                                            <img src={BASE_URL.img1 + item.img} alt={item.title} />
                                            <p className="home-about-title mh-elip">{item.title}</p>
                                            <p className="home-about-info">{item.summary}</p>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
export default Home;
