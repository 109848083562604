import { AuditOutlined, GlobalOutlined, InsuranceOutlined, TrademarkCircleOutlined } from "@ant-design/icons"
import { Button, Col, Row, Divider } from "antd"

export default () => {
    return (
        <>
            <Col span={24} id="user-info-nav-btn">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Divider children='常用业务' orientation="left" orientationMargin="8px" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/site" block className="hue-bg-blue" icon={<GlobalOutlined />}>网站出售</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/vouch" block className="hue-bg-danger" icon={<InsuranceOutlined />}>担保交易</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/brand" block className="hue-bg-warning" icon={<TrademarkCircleOutlined />}>商标注册</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/employer" block className="hue-bg-main" icon={<AuditOutlined />}>企业服务</Button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}