import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 充值
 * @param params 参数
 * @returns 
 */
export async function recharge(data: ADO.userRecharge): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/fund/recharge", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessage;
            if (data.success) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultMessage;
            }
        })
}

/**
 * 提现
 * @param params 参数
 * @returns 
 */
export async function withdraw(data: ADO.userRecharge): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/fund/withdraw", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessage;
            if (data.success) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultMessage;
            }
        })
}

/**
 * 列表
 * @param params 参数
 * @returns 
 */
export async function list(data: { isWithdraw?: boolean, current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.userRecharge>> {
    return requestX("GET", BASE_URL.url1 + "/user/fund/list", data)
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultPage<ADO.userRecharge>;
            if (data.size) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultPage<ADO.userRecharge>;
            }
        })
}
