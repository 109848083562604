import { Col, Row, Button, notification, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../config/config';
import { cartAdd } from 'src/services/api/UserCart';

const { Text } = Typography;

const SitesListItem = (props) => {

    let data = props.convert(props.data);

    const [api, contextHolder] = notification.useNotification();

    const navigage = useNavigate();

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="link" size="small" onClick={() => notification.destroy()}> 取消 </Button>
                <Button type="primary" size="small" onClick={() => navigage("/user/cart?goodsId=" + data.id)}>
                    去购物车结算
                </Button>
            </Space>
        );
        api.open({
            message: '商品已成功加入购物车！',
            description: <><p>{data.title}</p><p>数量:1</p></>,
            btn,
            key,
            placement: 'top'
        });
    };

    const addCart = async () => {
        const res = await cartAdd({ goodsId: data.id, goodsNum: data.goodsNum, goodsType: 1, goodsCount: 1 });
        if (res.success) {
            // setIsModalCart(true);
            openNotification()
        }
    }

    return (
        <>
            <Row className="site-show-item">
                <Col className="site-show-img" lg={2} xs={0} sm={4}>
                    <img src={BASE_URL.img1 + data.mainImg} alt="" />
                </Col>
                <Col lg={22} xs={24} sm={20}>
                    <Row>
                        <Col lg={24} xs={24} className="site-show-item-title">
                            <h3 className="common-elip"><Text keyboard strong type='danger'>{data.webType}</Text>{data.title}</h3>
                        </Col>
                        <Col lg={2} xs={4}>
                            <p>百度权重</p> <p>{data.bdr ? 'BDR' + data.bdr : '-'}</p>
                        </Col>
                        <Col lg={2} xs={3}>
                            <p>PR值</p> <p>{data.pr ? data.pr : '-'}</p>
                        </Col>
                        <Col lg={2} xs={4}>
                            <p>网络流量</p> <p>{data.ipNum ? data.ipNum : '-'}</p>
                        </Col>
                        <Col lg={4} xs={4}>
                            <p>网站域名</p> <p className="common-elip">{data.webUrl ? data.webUrl : '-'}</p>
                        </Col>
                        <Col lg={3} xs={4}>
                            <p>出售价格</p> <p className="comm-red">￥{data.fee}</p>
                        </Col>
                        <Col className="site-show-btn" lg={11} xs={24}>
                            <Link to={"" + data.webNum} target="_blank">查看详情</Link>
                            <span onClick={addCart}>加入购物车</span>
                        </Col>
                    </Row>
                </Col >
            </Row >
            {contextHolder}
            {/* {contextHolder}
            <Button type="primary" onClick={openNotification}>
                Open the notification box
            </Button> */}
        </>
    )
}
export default SitesListItem;