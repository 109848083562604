import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 列表
 * @param params 参数
 * @returns 
 */
export async function list(data: { current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.userWalletBill>> {
    return requestX("GET", BASE_URL.url1 + "/user/wallet/bill/list", data)
        .then(request => request.json()).then(res => {
            const list = res as unknown as ADO.ResultPage<ADO.userWalletBill>;
            if (list.size) {
                return list;
            } else {
                return errorHandler(list) as ADO.ResultPage<ADO.userWalletBill>;
            }
        })
}