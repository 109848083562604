import { BASE_URL } from "src/config/config";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 列表
 * @param data 
 * @returns 
 */
export async function listHot(): Promise<ADO.ResultMessageT<Array<ADO.news>>> {
    return requestX("GET", BASE_URL.url1 + "/news/list/hot")
        .then(request => request.json());
}


/**
 * 
 * @param id 资讯详情
 * @returns 
 */
export async function info(id: number | string): Promise<ADO.ResultMessageT<ADO.news>> {
    return requestX('GET', BASE_URL.url1 + '/news/' + id).then(request => request.json());
}