import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";


/**
 * 
 * @param params 参数
 * @returns  提交认证
 */
export async function put(data: ADO.userCard): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/card", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultMessage;
            }
        })
}

/**
 * 
 * @returns 获取认证信息
 */
export async function get(): Promise<ADO.ResultMessage> {
    return requestX("GET", BASE_URL.url1 + "/user/card").then(request => request.json()).then(res => {
        const result = res as unknown as ADO.ResultMessage;
        if (result.success) {
            return result;
        } else {
            return errorHandler(result) as ADO.ResultMessage;
        }
    })
}
