import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Select, Table, message } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import BigNumber from 'bignumber.js';

interface TableParams {
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue>,
    sorter?: SorterResult<ADO.website>
}

const UserSite = () => {
    const [data, setData] = useState<ADO.website[]>();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [tableParams, setTableParams] = useState<TableParams>({ pagination: { current: 1, pageSize: 10 } });

    const columns: ColumnsType<ADO.websiteSell> = [
        {
            title: '网址',
            dataIndex: 'url',
        },
        {
            title: "类型",
            dataIndex: 'webType',
            responsive: ['lg'],
            render: (value) => {
                switch (value) {
                    case 1: return "垂直行业"
                    case 2: return "地方门户"
                    case 3: return "影音游戏"
                    case 4: return "商城购物"
                    case 5: return "时尚女性"
                    case 6: return "文学小说"
                    case 7: return "生活服务"
                    case 8: return "其他"
                    default: return '-'
                }
            }
        },
        {
            title: '售价',
            dataIndex: 'fee',
            responsive: ['sm'],
            render: (value) => value ? '￥' + BigNumber(value).dividedBy(100).toFixed(2) : '-'
        },
        {
            title: '状态',
            dataIndex: 'state',
            render: (value, record) => {
                if (record.auditState === 1) {
                    return '审核中';
                } else if (record.auditState === 3) {
                    return '审核未通过';
                } else {
                    switch (value) {
                        case 1: return '在售';
                        case 2: return '交接中';
                        case 3: return '已售';
                        default: return '-'
                    }
                }
            }
        },
        {
            title: '添加时间',
            dataIndex: 'timeAdd',
            responsive: ['lg'],
            render: (value) => value.substring(0, 16)
        },
        {
            title: '操作'
            , key: 'action'
            , align: 'left'
            , render: (_, record) => (

                <>
                    {record.auditState === 2 ? <Button type='text' target='_blank' className="hue-danger" href={'/sites/' + record.webNum}>详情</Button> : <Button type='text' disabled>详情</Button>}
                    <Popconfirm
                        placement="topRight"
                        title="确认要删除吗？"
                        onConfirm={() => delRecord(record.id)}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button danger size="small" type='text'>删除</Button>
                    </Popconfirm>
                </>
            )
        }
    ];

    useEffect(() => {
        getList()
    }, [JSON.stringify(tableParams)])

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<ADO.vouch>,
    ) => {
        setTableParams({ pagination, filters, ...sorter });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const getList = () => {
        api.websiteSell.list({ ...tableParams.pagination }).then(res => {
            setTableParams({
                ...tableParams,
                pagination: {
                    current: res.current,
                    pageSize: res.size
                }
            })
            setData(res.records);
            setLoading(false);
        });
    }

    const delRecord = (id: number) => {
        api.websiteSell.del(id).then(res => {
            if (res.success) {
                setLoading(true);
                getList();
            }
        })
    }

    const onFinish = (value: ADO.websiteSell) => {
        value = { ...value, fee: BigNumber(value.fee).multipliedBy(100).toNumber() };
        api.websiteSell.put(value).then(res => {
            if (res.success) {
                message.success("登记成功，请等待审核！");
                form.resetFields();
                setIsModalOpen(false);
                getList();
            }
        })
    }

    return (
        <>
            <Col span={24}>
                <Button onClick={() => setIsModalOpen(true)} type='primary'>添加</Button>
            </Col>
            <Col span={24}>
                <Table
                    columns={columns}
                    rowKey={(record) => record.id ? record.id : 0}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
                <Modal title="出售登记" open={isModalOpen} onOk={() => form.submit()} onCancel={() => { setIsModalOpen(false); form.resetFields(); }} okText="确认" cancelText="取消">
                    <Form
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="网站名称"
                            name="title"
                            rules={[{ required: true, message: '请输入网站名称' }]}
                        >
                            <Input placeholder="请输入网站名称" />
                        </Form.Item>
                        <Form.Item
                            label="网站网址"
                            name="url"
                            rules={[{ required: true, message: '请输入网址!' }]}
                        >
                            <Input placeholder="请输入网址" />
                        </Form.Item>
                        <Form.Item
                            label="网站类型"
                            name="webType"
                            rules={[{ required: true, message: '请选择网站类型!' }]}
                        >
                            <Select
                                defaultValue={1}
                                options={[
                                    { value: 1, label: '垂直行业' },
                                    { value: 2, label: '地方门户' },
                                    { value: 3, label: '影音游戏' },
                                    { value: 4, label: '商城购物' },
                                    { value: 5, label: '时尚女性' },
                                    { value: 6, label: '文学小说' },
                                    { value: 7, label: '生活服务' },
                                    { value: 8, label: '其他' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="网站描述"
                            name="sellExplain"
                            rules={[{ required: true, message: '请输入网站描述!' }]}
                        >
                            <Input placeholder="请输入网站描述" />
                        </Form.Item>
                        <Form.Item
                            label="售价"
                            name="fee"
                            rules={[{ required: true, message: '请输入售价!' }]}
                        >
                            <InputNumber style={{ width: '100%' }} step="500" prefix="￥" precision={2} placeholder="0.01" />
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </>
    );
}
export default UserSite;