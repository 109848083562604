import { BASE_URL } from "src/config/config";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 添加
 * @param params 参数
 */
export async function getTime(): Promise<ADO.ResultMessageT<Date>> {
    return requestX("GET", BASE_URL.url1 + "/comm/time")
        .then(request => request.json())
}