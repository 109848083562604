import { tokenGet } from "@/utils";
import { Navigate, useLocation } from "react-router-dom";
/**
 * 判断是否有权限进入子组件（已登录）
 * @param {子組件} param0 
 * @returns 
 */
export default function AuthRoute({ children }) {
    const token = tokenGet();
    const location = useLocation();
    if (token) {
        return <>{children}</>
    } else {
        return <Navigate to={'/login?callback=' + encodeURIComponent(location.pathname + location.search + location.hash)} replace />
    }
}