import { Col, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import SitesFilter from '../Filter';
import SitesTj from '../Tj';
import SitesListItem from './Item';
import API from '../../../utils/API';
import { BASE_URL } from '../../../config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { scrollToAnchor } from '@/utils';

const SitesList = (props) => {
    const [items, setItems] = useState({ list: [], current: 1, limit: 15, total: 1, query: {} });

    useEffect(() => {
        API(BASE_URL.url1 + "/goods/site/list", 'post', {
            page: items.current,
            limit: items.limit, ...items.query
        }, false, false, "application/x-www-form-urlencoded").then(res => {
            if (res.records) {
                setItems({ ...items, list: res.records, current: res.current, total: res.total });
            } else {
                setItems({ ...items, list: [] })
            }
        });
    }, [JSON.stringify(items)]);//[items.current, items.query]

    //分页
    const onChangePage = (page) => {
        setItems({ ...items, current: page });
        scrollToAnchor('site-filter-box');
    };



    //网站筛选
    const clickFilter = (query) => {
        if (query && (Object.keys(query).length !== 0 || Object.keys(items.query).length !== 0)) {
            setItems({ ...items, query: query })
        }
    }

    return (
        <>
            <SitesTj />
            <Col xxl={16} xs={24} className="site-filter-div" id='site-filter-box'>
                <SitesFilter clickFilter={clickFilter} />
            </Col>
            <Col className="site-show" xxl={16} xs={24}>
                {items.list.map(item => (
                    <SitesListItem data={item} key={item.id} convert={props.convert} />
                ))}
            </Col>
            <Col className="site-page" xxl={16} xs={24}>
                <Pagination current={items.current} onChange={onChangePage} total={items.total} pageSize={items.limit} />
            </Col>
        </>
    )
}
export default SitesList;