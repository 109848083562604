import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { request } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 添加
 * @param params 参数
 */
export async function add(data: any): Promise<ADO.ResultMessage> {
    return request("PUT", BASE_URL.url1 + "/contract", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result, "/contract", false) as ADO.ResultMessage;
            }
        })
}