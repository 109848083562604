import { Tabs, Input, Col, Space, Pagination, Card, Avatar, Row, Button, Empty } from 'antd';
import type { TabsProps } from 'antd';
import { useEffect, useState } from 'react'
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import { BASE_URL } from 'src/config/config';
import { UserOutlined } from '@ant-design/icons';
import { userInfoGet } from '@/utils/token';
import { Link } from 'react-router-dom';

const { Search } = Input;

const userInfo = userInfoGet();

export default (props: any) => {
    const [page, setPage] = useState({ page: 1, limit: 15 });
    const [dataList, setDataList] = useState<ADO.ResultPage<ADO.orderPublic>>();

    useEffect(() => {
        getList(activeKey);
    }, []);

    const items: TabsProps['items'] = [
        {
            key: 'all',
            label: `全部订单`,
            children: <OrderList data={dataList?.records} />,
        },
        {
            key: '0',
            label: `待付款`,
            children: <OrderList data={dataList?.records} />,
        },
        {
            key: '1',
            label: `待收货`,
            children: <OrderList data={dataList?.records} />,
        },
        {
            key: '3',
            label: `待评价`,
            children: <OrderList data={dataList?.records} />,
        },
    ];

    const getList = (tabKey: any, current: number = 1, pageSize: number = 15) => {
        setActiveKey(tabKey);
        let data;
        if (tabKey != items[0].key) {
            data = { page: current, limit: pageSize, state: Number(tabKey) }
        } else {
            data = { page: current, limit: pageSize }
        }
        api.orderPublic.get(data).then((list) => {
            if (list.records) {
                setPage({ page: list.current, limit: list.size })
                setDataList(list);
            }
        })
    }
    const [activeKey, setActiveKey] = useState(items[0].key);
    const onSearch = (value: string) => console.log(value);

    const pageChange = (page: number, pageSize: number) => {
        getList(activeKey, page, pageSize);
    }
    const tabChange = (key: string) => {
        getList(key, 1, 10);
    };

    return (
        <Col span={24}>
            <Tabs activeKey={activeKey} items={items} onChange={tabChange} tabBarExtraContent={<Search placeholder="商品名称/订单号" onSearch={onSearch} enterButton />} />
            {dataList && dataList.total > 0 && <Pagination className='t_center' style={{ marginBottom: '25px' }} current={page.page} total={dataList?.total} onChange={pageChange} />}
        </Col>
    )

}

const OrderList = (props: { data?: Array<ADO.orderPublic> }) => {
    return (
        <>
            {props.data == undefined || props.data?.length < 1 && <Empty description="无数据" />}
            {props.data?.map(item => {
                let state;
                switch (item.state) {
                    case 0:
                        state = '未付款';
                        break;
                    case 1:
                        state = '已付款';
                        break;
                    case 2:
                        state = item.logisticsId ? '已发货' : '交接中';
                        break;
                    case 3:
                        state = '已完成';
                        break;
                    case 4:
                        state = '退货申请';
                        break;
                    case 5:
                        state = '退货中';
                        break;
                    case 6:
                        state = '已退货';
                        break;
                    case 7:
                        state = '取消交易';
                        break;
                }
                return (
                    <Col span={24} key={item.id}>
                        <Card title={<><Space><span>{item.timeAdd.substring(0, 10)}</span><span>订单号：{item.orderNo}</span></Space></>} size="small" headStyle={{ background: "#fafafa" }} style={{ marginBottom: '15px' }}>
                            <Card.Grid className='user-order-grid-first' hoverable={false}>
                                <Row>
                                    {item.orderDetails.map(product => (
                                        <Col span={24} key={product.id}>
                                            <Row>
                                                <Col span={16}>
                                                    <Space align='start'>
                                                        <Avatar shape="square" size={64} icon={<img src={BASE_URL.img1 + product.productImg} />} />
                                                        <Link to={'/user/order/detail/' + item.orderNo}>{product.productName}</Link>
                                                    </Space>
                                                </Col>
                                                <Col span={4} className='hue-gray'>x{product.number}</Col>
                                                <Col xs={0} xl={4}><Button type='link' href='http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes' rel='noreferrer nofollow' target='_blank'>申请售后</Button></Col>
                                                <Col xs={4} xl={0}><Button type='link' target='_blank' href={'/user/order/detail/' + item.orderNo}>订单详情</Button></Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Grid>
                            <Card.Grid className='user-order-grid' hoverable={false}>{userInfo?.name}<UserOutlined className='hue-gray' /></Card.Grid>
                            <Card.Grid className='user-order-grid hue-gray' hoverable={false} >
                                <span>￥{(item.orderAmountTotal * 0.01).toFixed(2)}</span>
                                <br />
                                <span className='user-order-pay-type'>在线支付</span>
                            </Card.Grid>
                            <Card.Grid className='user-order-grid' hoverable={false}>
                                <span className='hue-gray'>{state}</span>
                                <br />
                                <Button type='link' target='_blank' href={'/user/order/detail/' + item.orderNo}>订单详情</Button>
                            </Card.Grid>
                        </Card>
                    </Col>
                )
            })}
        </>
    )
}