import React from "react";
import '../../css/common.less'
import { Row, Col, Tooltip, Image } from "antd";
import { ToTopOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { BASE_URL } from "src/config/config";

const toTop = () => {
    document.documentElement.scrollTop = 0;
}

const Footer = () => {

    return (
        <>
            <Row justify="center" align="middle" className="footer ctn-box">
                <Col xxl={12} sm={20} xs={24} className="footer-left">
                    <Row>
                        <Col lg={4} xl={3} xs={6}>
                            <Tooltip title={<Image src={BASE_URL.img1 + 'comm/cyn-work-wx.png'} preview={false} />} color="white">
                                <p>关注微信<img src="/img/common/footer-weix.png" className="footer-weix" /></p>
                            </Tooltip>
                        </Col>
                        <Col lg={4} xl={3} xs={6}>
                            <Link to="/news">资讯中心</Link>
                        </Col>
                        <Col lg={4} xl={3} xs={6}>
                            <Link to="/help">帮助中心</Link>
                        </Col>
                        <Col lg={4} xl={3} xs={6}>
                            <Link to="/about">关于我们</Link>
                        </Col>
                        <Col lg={24} xl={{ span: 11, offset: 1 }} xs={24} sm={24}>
                            <Link to="#">在线客服（周一至周日9：00AM~12：00PM）</Link>
                        </Col>
                        <Col lg={24} xs={24}>
                            <Row>
                                <Col lg={4} xxl={3} xs={6} sm={3}><p>友情链接：</p></Col>
                                <Col lg={20} xxl={21} xs={18} sm={21}>
                                    <Row>
                                        <Col lg={8} xl={6} xxl={4} xs={12} sm={6}><Link to="#">创易鸟资产交易</Link></Col>
                                        <Col lg={8} xl={6} xxl={4} xs={12} sm={6}><Link to="#">创易鸟资产交易</Link></Col>
                                        <Col lg={8} xl={6} xxl={4} xs={12} sm={6}><Link to="#">创易鸟资产交易</Link></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}><p className="t_center">Copyright © 2019-2022 创易鸟虚拟资产交易平台 版权所有 蜀ICP备19021174号</p></Col>
                    </Row>
                </Col>
                <Col xxl={4} sm={4} xs={0} className="footer-right">
                    <span onClick={toTop}>PAGE TOP</span><ToTopOutlined />
                </Col>
            </Row>
        </>
    );
}

export default Footer;