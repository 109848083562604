import { Col, Row, Menu, MenuProps } from "antd";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UserCart from "./components/UserCart";
import UserInfo from "./components/UserInfo";
import UserOrder from "./components/UserOrder";
import UserWallet from "./components/UserWallet";
import UserRecharge from "./components/UserRecharge";
import UserBank from "./components/UserBank";
import UserVouch from "./components/business/UserVouch";
import UserSystem from "./components/UserSystem";
import UserBrand from "./components/business/UserBrand";
import UserEmployer from "./components/business/UserEmployer";
import "./index.less";
import UserSite from "./components/business/UserSite";
import NoFoundPage from "@/compontens/NoFoundPage";
import Business from "./components/business/Business";


const User: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();


    /**
     * 菜单点击事件
     * @param e 
     */
    const menuClick: MenuProps['onClick'] = (e) => {
        navigate(e.key);
    };

    /**
     * 构建菜单
     */
    const items = [
        getItem('个人中心', '/user'),
        getItem('我的订单', 'order'),
        // getItem('购物车', 'cart'),
        // getItem('我的收藏', 'sub5'),
        getItem('常用业务', 'business', null, [getItem('网站出售', 'business/site'), getItem('担保交易', 'business/vouch'), getItem('企业服务', 'business/employer'), getItem('商标注册', 'business/brand')], undefined),
        getItem('我的钱包', 'wallet'),
        getItem('我的银行卡', 'bank'),
        getItem('充值&提现', 'recharge'),
        getItem('个人设置', 'system'),
    ];

    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    return (
        <Row justify='center' gutter={[0, 8]} className="ctn-box user-box">
            <Col xs={0} lg={4} xxl={3}>
                <Menu
                    onClick={menuClick}
                    defaultSelectedKeys={['/index']}
                    selectedKeys={[location.pathname ? location.pathname.replace('/user/', '') : '/index']}
                    mode="inline"
                    defaultOpenKeys={['business']}
                    forceSubMenuRender={true}
                    items={items}
                    style={{ height: '100%' }}
                />
            </Col>
            <Col xs={24} lg={20} xxl={13} className="user-box-right">
                <Row justify="start" style={{ margin: '8px' }}>
                    <Routes>
                        <Route index element={<UserInfo />} />
                        <Route path="business/*" >
                            <Route index element={<Business />} />
                            <Route path="vouch" element={<UserVouch />} />
                            <Route path="brand" element={<UserBrand />} />
                            <Route path="employer" element={<UserEmployer />} />
                            <Route path="site" element={<UserSite />} />
                        </Route>
                        <Route path="cart" element={<UserCart />} />
                        <Route path="order" element={<UserOrder />} />
                        <Route path="wallet" element={<UserWallet />} />
                        <Route path="bank" element={<UserBank />} />
                        <Route path="recharge" element={<UserRecharge />} />
                        <Route path="system" element={<UserSystem />} />
                        <Route path="*" element={<NoFoundPage />} />
                    </Routes>
                </Row>
            </Col>
        </Row>
    )
}

export default User;