import { Row, Col, Button, Form, Input, Modal, message } from 'antd';
import './login.less';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import api from '@/services/api';
import { tokenSet } from '@/utils';
import { userInfoSet } from '@/utils/token';

const Login = () => {
    const [params] = useSearchParams();
    const [form] = Form.useForm();

    const [loginView, setLoginView] = useState(true);

    const onFinish = async (values: any) => {
        api.user.login({ username: values.username, password: values.password }).then(data => {
            if (data.success) {
                tokenSet(data.sign);
                userInfoSet(data.data);
                const callback = params.get('callback');
                window.location.href = callback ? callback : '/';
            } else {
                message.error(data.message ? data.message : '请求失败！');
            }
        })
    };

    return (
        <>
            <Row className="login-box">
                <Col lg={24} xs={24} className='login-bg'>
                    <Row className="login-content" justify='center'>
                        <Col lg={{ span: 8, offset: 8 }} xs={22}>
                            {loginView ? <><h2>您好,欢迎登录！</h2><br />
                                <Form form={form} name="basic" labelCol={{ span: 4, }} wrapperCol={{ span: 20, }}
                                    initialValues={{ remember: false, }} onFinish={onFinish} autoComplete="off">
                                    <Form.Item label="用户名" name="username" rules={[{ required: true, message: '用户名不能为空!', },]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="密&emsp;码" name="password" rules={[{ required: true, message: '密码不能为空!', },]}>
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ lg: { offset: 4, span: 20 }, xs: 24 }}>
                                        <Button type="primary" htmlType="submit">登&emsp;录</Button>
                                        <Button type="link" onClick={() => setLoginView(false)}>注&emsp;册</Button>
                                    </Form.Item>
                                </Form></> : <SignUp toLogin={setLoginView} />}
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
}

/**
 * 注册
 * @param props 
 * @returns 
 */
const SignUp = (props: { toLogin: any }) => {

    const onFinishSignUp = async (values: any) => {
        const data = await api.user.signup({ ...values });
        if (data.success) {
            setIsModalOpen(true);
        } else {
            message.error(data.message ? data.message : '请求失败！');
        }
    };

    const [signup] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (<>
        <h2>注册账号！</h2><br />
        <Form form={signup} name="signup" labelCol={{ span: 4, }} wrapperCol={{ span: 20 }} onFinish={onFinishSignUp} autoComplete="off">
            <Form.Item label="用户名" name="name" rules={[
                { required: true, message: '用户名不能为空!' }
                , { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/, message: '用户名只能包含数字,汉字,字母和下划线!' }
                , { pattern: /^.{6,15}$/, message: '用户名不能出现空格,且长度为6-15个字符!' }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item label="密&emsp;码" name="passPre" rules={[{ required: true, message: '密码不能为空!' }, { pattern: /^[\S]{8,16}$/, message: '密码必须8到16位,且不能出现空格!' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label="确认密码" name="pass" dependencies={['passPre']}
                rules={[{ required: true, message: '请再次输入密码!', },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('passPre') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('两次输入的密码不一致!'));
                    },
                })]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item label="手机号码" name="tel" rules={[{ required: true, message: '手机号码不能为空!' }, { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码!' }]}>
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ lg: { offset: 4, span: 20 }, xs: 24 }}>
                <Button type="primary" htmlType="submit">注&emsp;册</Button>
                <Button type="link" onClick={() => props.toLogin(true)}>已有账号，登录</Button>
            </Form.Item>
        </Form>
        <Modal title="成功" open={isModalOpen} onOk={() => { signup.resetFields(); setIsModalOpen(false); props.toLogin(true); }} onCancel={() => setIsModalOpen(false)} okText="确认" cancelText="取消">
            <p>账号注册成功，是否跳转登录？</p>
        </Modal>
    </>)
}

export default Login;