import { Breadcrumb, Col, Row, Tabs, Typography } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";

const { Paragraph } = Typography;

const About = () => {

    const items = [
        { label: '关于我们', key: 'about', children: <Content title="关于我们"><ItemAbout /></Content> },
        { label: '联系我们', key: 'contact', children: <Content title="联系我们"><ItemContact /></Content> },
        { label: '客服中心', key: 'service', children: <Content title="客服中心"><ItemService /></Content> },
    ];

    return (
        <Row justify='center' gutter={[0, 8]}>
            <Col lg={16} xs={24}>
                <Tabs
                    defaultActiveKey="1"
                    tabPosition={"left"}
                    items={items}
                    style={{ minHeight: '600px' }}
                />
            </Col>
        </Row>
    )
}
/**
 * 内容区域
 */
const Content = (props: { title: string, children: any }) => {
    return (
        <div className="hue-con-box">
            <Breadcrumb>
                <BreadcrumbItem>首页</BreadcrumbItem>
                <BreadcrumbItem>{props.title}</BreadcrumbItem>
            </Breadcrumb>
            <Typography.Title level={3}>{props.title}</Typography.Title>
            {props.children}
        </div>
    )
}
/**
 * 关于我们
 * @returns 
 */
const ItemAbout = () => {
    return (
        <>
            <Typography>
                <Paragraph>
                    创易鸟虚拟资产交易平台是四川创易鸟网络科技有限公司旗下门户网站，拥有数百万件商标待转让资源，每年转让出售的商标达到数百件；数百件网店转让、网站交易转让等虚拟资产交易平台案例；我公司位于四川天府之国——成都，是一家专业从事商标转让、商标注册、版权登记等综合型知识产权代理公司，我司经工商局批准和国家工商行政管理总局商标局正式授权备案机构。公司拥有多名资深的知识产权代理人，为企业提供优质、全面的知识产权服务。 经过多年的发展，专业水平不断提升,服务体系不断完善。
                </Paragraph>
                <Paragraph>
                    创易鸟网络科技有限公司是一家专业从事商标、专利、版权等综合性知识产权服务平台，是国家工商行政管理总局商标局备案机构，主要包括商标转让、商标注册、专利转让、版权登记等知识产权的申请、转让、维权服务；在国家大力倡导知识产权重要性的背景下，我司通过网络技术为全国各地企业提供全面的知识产权服务，公司始终奉行“客户至上、服务第一”经营理念，坚持“专业、诚信、优质、高效”服务宗旨，深受广大客户的好评，为建成中国特色、世界水平的知识产权强国做出我们应有的贡献。
                </Paragraph>
                <Paragraph>
                    公司成立于2019年，在习近平新时代中国特色社会主义思想指导下不忘初心、不懈奋斗；在大家的共同努力下，公司规模不断扩大，办公环境不断优越，福利待遇不断提升，服务质量与服务效率不断提高，努力让我们的服务走向全国、走向全世界。
                </Paragraph>
            </Typography>
        </>
    )
}

/**
 * 联系我们
 * @returns 
 */
const ItemContact = () => {
    return (
        <>
            <Typography>
                <Paragraph>创易鸟商务合作<br />邮箱：chuangyiniao@163.com<br />电话：173 9885 9411</Paragraph>
                <Paragraph>公司地址：四川省成都市金牛区五块石龙湖北城天街<br />客户服务热线：173 9885 9411 ，接听时间：09：30-18：30。</Paragraph>
            </Typography>
        </>
    )
}

/**
 * 客服中心
 * @returns 
 */
const ItemService = () => {
    return (
        <>
            客服中心
        </>
    )
}

export default About;


