import { userInfoGet } from "@/utils/token";
import { AccountBookOutlined, AuditOutlined, GlobalOutlined, InsuranceOutlined, SettingOutlined, ShoppingOutlined, SolutionOutlined, TrademarkCircleOutlined } from "@ant-design/icons";
import { Descriptions, Typography, Col, Row, Button, Divider } from "antd"
import BigNumber from "bignumber.js";
import Statistic from "./business/components/Statistic";

const { Text } = Typography;

const UserInfo = (props: any) => {
    const userInfo = userInfoGet();

    return (
        <>
            <Col span={24}>
                <Descriptions title={<img width={60} src="/img/common/user-log.png" alt="user-log" />} column={2}>
                    <Descriptions.Item label="会员名">{userInfo?.name}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{userInfo?.tel ? userInfo.tel : '未填写'}</Descriptions.Item>
                    <Descriptions.Item label="账户余额"><Text type="warning" strong>￥{BigNumber(userInfo?.balance ? userInfo.balance : 0).multipliedBy(0.01).toFixed(2)}</Text></Descriptions.Item>
                    <Descriptions.Item label="冻结金额"><Text type="success" strong>￥{BigNumber(userInfo?.blocking ? userInfo.blocking : 0).multipliedBy(0.01).toFixed(2)}</Text></Descriptions.Item>
                    <Descriptions.Item label="实名认证">{userInfo?.state === 2 ? '已认证' : '未认证'}</Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <Statistic />
            </Col>
            <Col span={24} id="user-info-nav-btn">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Divider children='常用业务' orientation="left" orientationMargin="8px" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/site" block className="hue-bg-blue" icon={<GlobalOutlined />}>网站出售</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/vouch" block className="hue-bg-danger" icon={<InsuranceOutlined />}>担保交易</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/brand" block className="hue-bg-warning" icon={<TrademarkCircleOutlined />}>商标注册</Button>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Button size="large" type="text" href="/user/business/employer" block className="hue-bg-main" icon={<AuditOutlined />}>企业服务</Button>
                    </Col>
                    <Col span={24}>
                        <Divider children='常用导航' orientation="left" orientationMargin="8px" />
                    </Col>
                    <Col lg={6} xs={12}><Button size="large" type="text" href="/user/order" block className="hue-bg-blue" icon={<ShoppingOutlined />}>我的订单</Button></Col>
                    <Col lg={6} xs={12}><Button size="large" type="text" href="/user/wallet" block className="hue-bg-danger" icon={<AccountBookOutlined />}>我的钱包</Button></Col>
                    <Col lg={6} xs={12}><Button size="large" type="text" href="/user/system?initTab=2" block className="hue-bg-warning" icon={<SolutionOutlined />}>实名认证</Button></Col>
                    <Col lg={6} xs={12}><Button size="large" type="text" href="/user/system" block className="hue-bg-main" icon={<SettingOutlined />}>个人设置</Button></Col>
                </Row>
            </Col>
        </>
    )
}

export default UserInfo;