import { Col, Row, Tooltip, Image } from 'antd';

const SitesChat = (props) => {
    return (
        <>
            <Col span={24}>
                <Row justify='center'>
                    <Col xxl={16} xs={24} className='site-chat'>
                        <p>
                            <span>在线客服</span>
                            <a href="http://wpa.qq.com/msgrd?v=3&uin=3181454981&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>QQ客服1</a>
                            <a href="http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>QQ客服2</a>
                            <Tooltip title={<Image src='/img/common/site-wx-temp.png' preview={false} />} color="white">
                                <span>微信客服1</span>
                            </Tooltip>
                            <Tooltip title={<Image src='/img/common/site-wx-temp.png' preview={false} />} color="white">
                                <span>微信客服2</span>
                            </Tooltip>
                            <a href="http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>在线投诉</a>
                            <a href="#" rel='noreferrer nofollow' target='_blank'>真假客服验证</a>
                            <a href="tel:18482346807" rel='noreferrer nofollow' target='_blank'>服务热线：184 8234 6807</a>
                        </p>
                        <p className="common-elip">
                            <span>交易动态</span>
                            <span>50分钟前</span>
                            <span>A****1 以 <i>￥000.00</i> 购买 网站标题网站标题网站标题网站标题网站标题</span>
                        </p>
                    </Col>
                </Row>
            </Col>
        </>
    )
}
export default SitesChat;