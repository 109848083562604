import { createSlice } from '@reduxjs/toolkit'
import { tokenGet, tokenSet } from '@/utils';

const userStore = createSlice({
    name: 'user'
    , initialState: {
        token: tokenGet() || ''
        , userInfo: {}
    }
    , reducers: {
        setToken(state, action) {
            state.token = action.payload
            tokenSet(action.payload)
        }
        , setUserInfo(state, action) {
            state.userInfo = action.payload
        }
    }
})

const { setToken, setUserInfo } = userStore.actions;

const reducer = userStore.reducer;

//异步方法封装
// const fetchLogin = (formData: ADO.userLogin): any => {
//     return async (dispatch: Dispatch) => {
//         api.user.login(formData).then(data => {
//             if (data.success) {
//                 dispatch(setToken(data.data))
//             }
//         })
//         /*api.user.login(formData).then(data => {
//             if (data.success) {
//                 tokenSet(data.data);
//                 const callback = params.get('callback');
//                 callback ? navigate(callback) : navigate('/');
//             } else {
//                 message.error(data.message ? data.message : '请求失败！');
//             }
//         })*/
//     }
// }

export { setToken, setUserInfo }

export default reducer;