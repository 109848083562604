import api from "@/services/api";
import { ADO } from "@/services/api/typings";
import { CarryOutTwoTone, CreditCardTwoTone, MoneyCollectTwoTone, ShoppingTwoTone } from "@ant-design/icons"
import { Card, Col, Divider, Row, Statistic } from "antd"
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";

export default () => {

    const [statistic, setStatistic] = useState<ADO.statistic>({ month: 1, trade_money: 0, trade_num: 0, recharge: 0, withdraw: 0 });

    useEffect(() => {
        api.statistic.base().then(res => { return res.data && setStatistic(res.data) })
    }, [])

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Divider children={statistic.month + "月统计"} orientation="left" orientationMargin="8px" />
            </Col>
            <Col lg={6} xs={12}>
                <Card><Statistic title="订单数量" value={statistic.trade_num} prefix={<CarryOutTwoTone />} /></Card>
            </Col>
            <Col lg={6} xs={12}>
                <Card><Statistic title="交易金额" formatter={(value) => (<>{BigNumber(statistic.trade_money).multipliedBy(0.000001).toFixed(2)}万</>)} prefix={<ShoppingTwoTone />} /></Card>
            </Col>
            <Col lg={6} xs={12}>
                <Card><Statistic title="充值金额" formatter={(value) => (<>{BigNumber(statistic.recharge).multipliedBy(0.000001).toFixed(2)}万</>)} prefix={<MoneyCollectTwoTone />} /></Card>
            </Col>
            <Col lg={6} xs={12}>
                <Card><Statistic title="提现金额" formatter={(value) => (<>{BigNumber(statistic.withdraw).multipliedBy(0.000001).toFixed(2)}万</>)} prefix={<CreditCardTwoTone />} /></Card>
            </Col>
        </Row>
    )
}