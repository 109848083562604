import { Link } from 'react-router-dom';
import { Row, Col, Select, Input, Button, Form, notification, Modal, Space, Typography } from 'antd';
import { createFromIconfontCN, TeamOutlined, MobileOutlined } from '@ant-design/icons';
import './index.less';
import { BASE_URL } from "../../config/config";
import API from "../../utils/API";
import { useEffect, useState } from 'react';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import { GlobalSettingType } from 'src/services/enums';
// import api from 'src/services/api';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1753518_q77wlfezi5f.js',//自定义cdn
})

const { Text, Paragraph } = Typography;

const Employer = () => {

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [news, setNews] = useState<any[]>();

    /**
     * 添加联系方式
     * @param values 
     */
    const csSub = (values: any) => {
        API(BASE_URL.url1 + '/contract', 'put', { ...values, dataType: 2 }, false, false, 'application/x-www-form-urlencoded').then(res => {
            if (res.success) {
                setIsModalOpen(true);
            } else {
                notification.error({ message: res.message });
            }
        }).catch(e => {
            console.log(e);
        });
    }

    /**
     * 申请企业服务
     * @param value
     */
    const employerAdd = async (mold: number) => {
        const data: ADO.employer = { mold: mold }
        const res = await api.employer.add(data);
        if (res.success) {
            setIsModalOpen(true);
        } else {
            notification.error({
                message: res.message
            });
        }
    }

    useEffect(() => {
        //资讯
        api.globalSetting.list({ type: GlobalSettingType.EMPLOYER_NEWS, pageSize: 5 }).then(res => {
            setNews(res.records);
        })
    }, [])


    return (
        <>
            {/* 首屏背景 */}
            <Row className="cs-top-box" justify='center'>
                <Col xxl={16} xs={24} className="cs-top-box-title">
                    <h2>创易鸟企业服务中心</h2>
                </Col>
                <Col lg={24} xxl={16} xs={0} className="ctn-box">
                    <Row justify='center'>
                        <Col lg={24} className="cs-top-form-title">
                            <p>免费预约顾问</p>
                        </Col>
                        <Col lg={24} xs={0} md={0} className="cs-top-form">
                            <Form form={form} name="user_cs" onFinish={csSub} autoComplete="off">
                                <Form.Item>
                                    <Input.Group compact size='large'>
                                        <Form.Item noStyle name="dataValue" rules={[{ required: true, message: '请选择需要的服务类别!' }]}>
                                            <Select placeholder="请选择需要的服务类别" style={{ width: '20%' }} size="large">
                                                <option value="1">代理记账</option>
                                                <option value="2">公司注册</option>
                                                <option value="3">刻制印章</option>
                                                <option value="4">工商信息变更</option>
                                                <option value="5">工商异常处理</option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item noStyle name="name" rules={[{ required: true, message: '请输入您的姓名!' }]}>
                                            <Input name="username" addonBefore={<TeamOutlined />} placeholder="请输入您的姓名"
                                                style={{ width: '30%' }} />
                                        </Form.Item>
                                        <Form.Item noStyle name="tel" rules={[{ required: true, message: '请输入您的手机号码!' }, { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码!' }]}>
                                            <Input name="tel" addonBefore={<MobileOutlined />} placeholder="请输入您的手机号码"
                                                style={{ width: '35%' }} />
                                        </Form.Item>
                                        <Form.Item noStyle>
                                            <Button type="primary" htmlType="submit" size="large" style={{ width: '15%' }}>免费预约</Button>
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col lg={24} xs={24} className="cs-top-form-more">
                            <Paragraph>
                                <Text type="danger">更多服务：</Text>
                                <Space>
                                    <Button type='link'>代理记账</Button>
                                    <Button type='link'>公司注册</Button>
                                    <Button type='link'>工商股权变更</Button>
                                </Space>
                            </Paragraph>
                            <Paragraph>
                                <Text type="danger">已入驻城市：</Text>
                                <Space>
                                    <Button type='link'>成都</Button>
                                    <Button type='link'>绵阳</Button>
                                    <Button type='link'>巴中</Button>
                                </Space>
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* 精选服务 */}
            <Row className="cs-services-box ctn-box" justify='center'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>精选服务</h2>
                    <p>FEATURED SERVICES</p>
                </Col>
                <Col xxl={16} className="cs-services-content">
                    <Row justify='space-evenly'>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>公司注册+银行对公开户</h3>
                            <p>有限公司、个体、分公司、外资公司注册</p>
                            <p><span>专业顾问</span><span>需提供经营范围</span></p>
                            <p>￥800.00起</p>
                            <Button onClick={() => { employerAdd(1) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>刻制印章</h3>
                            <p>光敏章、铜章、范转章刻印，0利润服务</p>
                            <p><span>正规上网章</span><span>公安系统备案</span></p>
                            <p>￥150.00/个</p>
                            <Button onClick={() => { employerAdd(2) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>代理记账</h3>
                            <p>专业会计一对一服务，省时省力</p>
                            <p><span>专业顾问</span><span>管家服务</span></p>
                            <p>￥2000.00/年起</p>
                            <Button onClick={() => { employerAdd(3) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>工商股权变更</h3>
                            <p>公司股东股资比例、股权结构变更</p>
                            <p><span>新股东占股20%以上需签字</span><span>专业顾问</span></p>
                            <p>￥1000.00/次</p>
                            <Button onClick={() => { employerAdd(4) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>工商经营范围变更</h3>
                            <p>新增，变更营业执照经营范围</p>
                            <p><span>及时变更杜绝公司异常</span><span>管家服务</span></p>
                            <p>￥2000.00/年起</p>
                            <Button onClick={() => { employerAdd(5) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>工商注册地址变更</h3>
                            <p>变更营业执照注册地址</p>
                            <p><span>支持同时同区、异市变更</span><span>专业顾问</span></p>
                            <p>点击联系客服询价</p>
                            <Button onClick={() => { employerAdd(6) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>工商异常处理</h3>
                            <p>处理各种工商异常问题</p>
                            <p><span>方式正规，安全可靠</span><span>专业顾问</span></p>
                            <p>点击联系客服询价</p>
                            <Button onClick={() => { employerAdd(7) }} block size='large'>立即预约</Button>
                        </Col>
                        <Col lg={11} xs={24} xxl={7}>
                            <h3>更多服务</h3>
                            <p>更多企业服务，请资讯客服了解</p>
                            <p><span>工作日9:00AM~12:00PM</span><span>专业顾问</span></p>
                            <p>￥0.00起</p>
                            <Button block size='large' href='#'>咨询客服</Button>
                        </Col>
                        <Col lg={23} xs={24} xxl={7}>
                            <h3>创易鸟企业服务在线客服</h3>
                            <p>电话1：173 5865 6261</p>
                            <p>电话2：183 8429 9094</p>
                            <p>QQ客服：2845295875</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* 资讯中心 */}
            <Row className="cs-news-box ctn-box" justify='center'>
                <Col xxl={4} xs={8} className='cs-news-left'>
                    <div className="cs-news-left-1 nav-title">
                        <h2>资讯中心</h2>
                        <p>INFOMATION</p>
                    </div>
                    <div className="cs-news-left-3">
                        <Link to="/news" className="more">
                            MORE<IconFont type="icon-add" className='more-icon' />
                        </Link>
                    </div>
                </Col>
                <Col xxl={12} xs={16} className="cs-news-right">
                    <Row>
                        {news && news.map((news: ADO.news) => (
                            <Col lg={24} xs={24} key={news.id}>
                                <p>{news.timeAdd && news.timeAdd.substring(0, 10)}</p>
                                <Link to={"/news/" + news.id} target="_blank">{news.title}</Link>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Modal title="成功" open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)} footer={[
                <Button key="ok" onClick={() => setIsModalOpen(false)} type="primary" > 确定 </Button>,
            ]}>
                <p>提交成功，平台将在一个至三个工作日内与您联系，请保持手机信号畅通！</p>
            </Modal>
        </>
    )
}
export default Employer;