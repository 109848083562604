import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 列表
 * @param params 参数
 * @returns 
 */
export async function put(data: ADO.userBank): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/bank", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessage;
            if (data.success) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultMessage;
            }
        })
}

/**
 * 列表
 * @param params 参数
 * @returns 
 */
export async function list(data: { page: number, limit: number }): Promise<ADO.ResultPage<ADO.userBank>> {
    return requestX("GET", BASE_URL.url1 + "/user/bank/list", data)
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultPage<ADO.userBank>;
            if (data.size) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultPage<ADO.userBank>;
            }
        })
}
/**
 * 
 * @returns 银行卡列表全部
 */
export async function listAll(): Promise<ADO.ResultMessageT<Array<ADO.userBank>>> {
    return requestX("GET", BASE_URL.url1 + "/user/bank/all")
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessageT<Array<ADO.userBank>>;
            if (data.success) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultMessageT<Array<ADO.userBank>>;
            }
        })
}

/**
 * 删除
 * @param params 参数
 * @returns 
 */
export async function del(id: number): Promise<ADO.ResultMessage> {
    return requestX("DELETE", BASE_URL.url1 + "/user/bank", { id: id }, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessage;
            if (data.success) {
                return data;
            } else {
                return errorHandler(data) as ADO.ResultMessage;
            }
        })
}