import { Col, Typography, Divider, Radio, Row, Spin, Image, Space, Descriptions, Button } from "antd"
import api from "src/services/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { ADO } from "src/services/api/typings";
import { BASE_URL } from "src/config/config";
import BigNumber from "bignumber.js";

const { Text, Title } = Typography;

const OrderInfo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [detail, setDetail] = useState<ADO.orderPublic>();

    const details = async () => {
        if (params.no) {
            const result = await api.orderPublic.getByOrderNo(params.no);
            if (result.success) {
                setDetail(result.data);
            } else {
                navigate(result.code ? ('/' + result.code) : '/');
            }
        } else {
            navigate("/404");
        }
    }




    useEffect(() => {
        details();
    }, [params.no])


    return (
        <Spin spinning={detail ? false : true}>
            <Row justify='center' align='middle' className="trade_box">
                <Col lg={16} xs={24}>
                    {detail?.orderDetails.map(product => (
                        <Col span={24} key={product.id}>
                            <Title level={5}>订单信息</Title>
                            <Descriptions bordered size="small" layout="vertical" column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}>
                                <Descriptions.Item label="商品">
                                    <Space align="start" size="middle">
                                        {product.productImg && <Image src={BASE_URL.img1 + product.productImg} preview={false} width={120} style={{ maxHeight: '90px' }} />}
                                        <Text>{product.productName}</Text>
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label="数量">X{product.number}</Descriptions.Item>
                                <Descriptions.Item label="单价">￥{BigNumber(product.productPrice).multipliedBy(0.01).toFixed(2)}</Descriptions.Item>
                                <Descriptions.Item label="操作"><Button type="link" href="http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes" rel='noreferrer nofollow' target='_blank'>申请售后</Button></Descriptions.Item>
                            </Descriptions>
                        </Col>
                    ))}
                    {/*  */}
                </Col>
                <Col lg={16} xs={24}>
                    <Divider />
                    <Title level={5}>支付方式</Title>
                    <Radio.Group defaultValue="online">
                        <Radio.Button value="online">在线支付</Radio.Button>
                        <Radio.Button value="ship" disabled>货到付款</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col lg={16} xs={24}>
                    <Divider />
                    <Title level={5}>使用优惠/抵用</Title>
                    <p>无</p>
                </Col>
                <Col lg={16} xs={24} className=" trade_item">
                    <Divider />
                    <Row className="trade_item_sub t_right">
                        <Col lg={21} xs={16}>总商品金额：</Col>
                        <Col lg={3} xs={8}>￥{BigNumber(detail ? detail.orderAmountTotal : 0).multipliedBy(0.01).toFixed(2)}</Col>
                        <Col lg={21} xs={16}>运费：</Col>
                        <Col lg={3} xs={8}>￥0.00</Col>
                    </Row>
                </Col>
                <Col lg={16} xs={24} className="trade_item trade_item_pay">
                    <Row className="trade_item_sub t_right" align="bottom">
                        <Col lg={21} xs={16}>实付款：</Col>
                        <Col lg={3} xs={8} className="trade_item_money">￥{BigNumber(detail ? detail.orderAmountTotal : 0).multipliedBy(0.01).toFixed(2)}</Col>
                    </Row>
                </Col>

            </Row>
        </Spin>
    )
}
export default OrderInfo;