import React, { useEffect, useState } from "react";
import { cartList } from "src/services/api/UserCart";
import { ADO } from "src/services/api/typings";

const UserCart: React.FC = () => {

    const [items, setItems] = useState<ADO.userCartItems>({ data: [] });

    /**
     * 购物车列表
     */
    const api_cart_list = async () => {
        const res = await cartList();
        if (res?.success) {
            setItems(res);
        }
    }

    useEffect(() => {
        api_cart_list();
        console.log(items);
    }, [items.data?.length])


    return (
        <>
            <h1>购物车</h1>
            {items.data?.map(record => (
                <p key={record.id}>{record.goodsId}</p>
            ))}
        </>
    )
}
export default UserCart;