import { Col, Row, Space, Dropdown, Drawer, Avatar } from 'antd';
import '../../css/common.less';
import '../../css/global.less';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TeamOutlined, MenuFoldOutlined, HomeOutlined, LogoutOutlined, LoginOutlined, InsuranceOutlined, TrademarkOutlined, FundProjectionScreenOutlined, IeOutlined, UserOutlined, ShareAltOutlined, PropertySafetyOutlined, ShoppingOutlined, WalletOutlined, CreditCardOutlined, SettingOutlined, } from '@ant-design/icons';
import api from 'src/services/api';

import type { MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { tokenGet } from '@/utils';
import { userInfoGet } from '@/utils/token';


const Head = (props: any) => {

    const [showMenu, setShowMenu] = useState(false);
    const [routeList, setRouteList] = useState<Array<{ [option: string]: any }>>();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        createRouteItem();
    }, [])

    const navActive = ({ isActive }: any) => {
        return isActive ? "nav-this" : ""
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/user">个人中心</Link>
            ),
        },
        {
            key: '2'
            , label: (
                <Link to="/user/order">我的订单</Link>
            )
        },
        {
            key: '3'
            , label: (
                <Link to="/user/wallet">我的钱包</Link>
            )
        },
        {
            key: '4',
            label: (
                <a onClick={() => { api.user.logOut() }}>退出登录</a>
            ),
        },
    ];

    const createRouteItem = () => {
        let item: Array<{ [option: string]: any }>;
        if (location.pathname.startsWith("/user")) {
            item = [
                { key: '1', href: '/', label: '返回首页', icon: <HomeOutlined /> },
                { key: '2', href: '/user', label: '个人中心', icon: <UserOutlined /> },
                { key: '3', href: '/user/order', label: '我的订单', icon: <ShoppingOutlined /> },
                { key: '5', href: '/user/wallet', label: '我的钱包', icon: <WalletOutlined /> },
                { key: '6', href: '/user/bank', label: '我的银行卡', icon: <CreditCardOutlined /> },
                { key: '7', href: '/user/recharge', label: '充值&提现', icon: <PropertySafetyOutlined /> },
                { key: '8', href: '/user/system', label: '个人设置', icon: <SettingOutlined /> },
                { key: '9', href: '#', label: '退出登录', icon: <LogoutOutlined />, click: () => api.user.logOut() }
            ];
        } else {
            item = [
                { key: '1', href: '/', label: '首页', icon: <HomeOutlined />, },
                { key: '2', href: '/sites', label: '网站出售', icon: <IeOutlined /> },
                { key: '3', href: '/vouch', label: '担保交易', icon: <InsuranceOutlined /> },
                { key: '4', href: '/employer', label: '企业服务', icon: <FundProjectionScreenOutlined /> },
                { key: '5', href: '/brand', label: '商标注册', icon: <TrademarkOutlined /> },
                { key: '6', href: '/news', label: '资讯中心', icon: <ShareAltOutlined /> },
            ];
            if (tokenGet()) {
                item.push({ key: '12', href: '/user', label: '个人中心', icon: <UserOutlined /> }, { key: '6', href: '#', label: '退出登录', icon: <LogoutOutlined />, click: () => api.user.logOut() })
            } else {
                item.push({ key: '12', href: '/login', label: '用户登录', icon: <LoginOutlined /> })
            }
        }
        setRouteList(item);
    }


    return (
        <>
            <Row align="middle" justify='start' className={props.index ? "head-top-nav-box-index" : "head-top-nav-box"}>
                <Col xs={20} sm={20} xl={8} className="head-top-nav-left">
                    <Link to="/">
                        <img src={props.index ? "/img/common/logo-white.png" : "/img/common/logo-red.png"} alt="创易鸟logo-虚拟资产交易平台" />
                        <span>创易鸟虚拟资产交易平台</span><br />
                        <span>www.chuangyiniao.cn</span>
                    </Link>
                </Col>
                <Col sm={0} xs={0} xl={16} className="head-top-nav-right">
                    <Row align="middle" justify='end'>
                        <Col xl={23} md={0}>
                            <Space size="large" className='fr'>
                                <Link to="/" > 首&emsp;&emsp;页 </Link>
                                <NavLink to="/sites" className={navActive}> 网站交易 </NavLink>
                                <NavLink to="/brand" className={navActive}> 商标注册 </NavLink>
                                <NavLink to="/vouch" className={navActive}> 担保交易 </NavLink>
                                <NavLink to="/employer" className={navActive}> 企业服务 </NavLink>
                                <NavLink to="/news" className={navActive} > 资讯中心 </NavLink>
                            </Space>
                        </Col>
                        <Col lg={0} xl={1}>{/** 加入购物车，这里改为3 */}
                            <Space size={'large'}>
                                {/* <Link to="/user/cart"><ShoppingCartOutlined /></Link> */}
                                {localStorage.getItem("token") ? (
                                    <Dropdown menu={{ items }} placement='bottom'>
                                        <a onClick={() => { navigate("/user") }}><TeamOutlined /></a>
                                    </Dropdown>
                                ) : (
                                    <>
                                        <Link to="/login"><TeamOutlined /></Link>
                                        {/* <Link to="/login"><AppstoreOutlined /></Link> */}
                                    </>
                                )}
                            </Space>
                        </Col>
                    </Row>
                </Col >
                <Col sm={4} xs={4} xl={0}>
                    <MenuFoldOutlined className="head-nav-icon" style={{ fontSize: '22px', display: 'block', textAlign: 'right' }} onClick={() => setShowMenu(true)} />
                </Col>
            </Row >
            <Drawer width={200} onClose={() => setShowMenu(false)} open={showMenu} closable={false} className='drawer-menu'>
                {tokenGet() && <div className='user-info'>
                    <Avatar style={{ verticalAlign: 'middle', backgroundColor: '#EF233C' }} size="large">
                        {'m'}
                    </Avatar>
                    <h4>您好，{userInfoGet()?.name}</h4>
                </div>}
                {routeList && routeList.map(item => {
                    return <a href={item.href} key={item.key} onClick={() => { setShowMenu(false); item.click(); }}>{item.icon}{item.label}</a>
                })}
            </Drawer>
            <Outlet />
        </>
    );
}

export default Head;