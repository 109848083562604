import { Button, Col, Descriptions, Typography, Divider, Image, Radio, Row, Spin, Modal, Space } from "antd"
import './index.less';
import api from "src/services/api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import { ADO } from "src/services/api/typings";
import { BASE_URL } from "src/config/config";
import BigNumber from "bignumber.js";

const { Title, Text } = Typography;

const Trade = (props: any) => {

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [detail, setDetail] = useState<ADO.trade>({ id: 0, count: 1, price: 0, name: '', img: '' });
    const [modal, contextHolder] = Modal.useModal();
    const data: any = location.state ? location.state : { goodsType: params.goodsType, no: params.no }

    const details = async () => {
        if (data) {
            const result = await api.trade.getBaseInfo(data);
            if (result.data) {
                setDetail(result.data);
            } else {
                navigate(result.code ? ('/' + result.code) : '/');
            }
        } else {
            navigate("/404");
        }
    }


    const orderPay = async () => {
        const result = await api.orderPublic.pay({
            id: detail.id,
            payType: 1,
            count: 1,
            uRemark: "",
            goodsType: data.goodsType
        });
        if (result.success) {
            modal.success({
                title: '成功'
                , content: (<p>{result.message}</p>)
                , okText: '确定'
                , onOk: () => {
                    navigate('/user/order');
                }
            })
        } else if (result.code === 10001) {
            modal.error({
                title: '失败'
                , content: (<p>支付失败，用户余额不足，是否前往充值？</p>)
                , okText: '确定'
                , cancelText: '取消'
                ,onOk(...args) {
                    window.open('/user/recharge','_black')
                },
            })
        }
        else {
            modal.error({
                title: '失败'
                , content: (<p>支付失败，{result.message}</p>)
                , okText: '确定'
                , cancelText: '取消'
            })
        }
    }

    useEffect(() => {
        details();
    }, [location.state])


    return (
        <Spin spinning={detail ? false : true}>
            <Row justify='center' align='middle' className="trade_box">
                <Col lg={16} xs={24}>
                    <Title level={5}>订单信息</Title>
                    <Descriptions bordered size="small" layout="vertical" column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}>
                        <Descriptions.Item label="商品">
                            <Space align="start" size="middle">
                                {detail.img && <Image src={BASE_URL.img1 + detail.img} preview={false} width={120} style={{ maxHeight: '90px' }} />}
                                <Text>{detail.name}</Text>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="数量">{detail.count}</Descriptions.Item>
                        <Descriptions.Item label="单价">￥{BigNumber(detail.price).multipliedBy(0.01).toFixed(2)}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col lg={16} xs={24}>
                    <Divider />
                    <Title level={5}>支付方式</Title>
                    <Radio.Group defaultValue="online">
                        <Radio.Button value="online">在线支付</Radio.Button>
                        <Radio.Button value="ship" disabled>货到付款</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col lg={16} xs={24}>
                    <Divider />
                    <Title level={5}>使用优惠/抵用</Title>
                    <p>无</p>
                </Col>
                <Col lg={16} xs={24} className=" trade_item">
                    <Divider />
                    <Row className="trade_item_sub t_right">
                        <Col lg={21} xs={16}>总商品金额：</Col>
                        <Col lg={3} xs={8}>￥{BigNumber(detail.price).multipliedBy(0.01).toFixed(2)}</Col>
                        <Col lg={21} xs={16}>运费：</Col>
                        <Col lg={3} xs={8}>￥0.00</Col>
                    </Row>
                </Col>
                <Col lg={16} xs={24} className="trade_item trade_item_pay">
                    <Row className="trade_item_sub t_right" align="bottom">
                        <Col lg={21} xs={16}>应付总额：</Col>
                        <Col lg={3} xs={8} className="trade_item_money">￥{BigNumber(detail.price).multipliedBy(0.01).toFixed(2)}</Col>
                    </Row>
                </Col>
                <Col lg={16} xs={24} className="t_right trade_item">
                    <Button type="primary" onClick={orderPay}>支付订单</Button>
                </Col>
            </Row>
            {contextHolder}
        </Spin>
    )
}
export default Trade;