import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";


type type_list = ADO.ResultMessageT<{ [key: string]: ADO.collect }>

/**
 * 获取
 * @param params 参数
 */

export async function get(data: any): Promise<type_list> {
    return requestX("GET", BASE_URL.url1 + "/user/collect", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as type_list;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result) as type_list;
            }
        })
}
