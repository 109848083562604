import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 添加
 * @param params 参数
 */
export async function add(data: ADO.employer): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/employer", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result, "/employer", false) as ADO.ResultMessage;
            }
        })
}
/**
 * 列表
 * @param data 
 * @returns 
 */
export async function list(data: { current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.employer>> {
    return requestX("GET", BASE_URL.url1 + "/user/employer/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<ADO.employer>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultPage<ADO.employer>;
            }
        })
}