import { Col, Row, Select, Input, InputNumber, Checkbox, Tooltip, Space, Button } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';

const { Option } = Select;

const SitesFilter = (props) => {
    const [state, setState] = useState({
        item: {
            webType: [
                { name: '全部', all: true, active: true, v: 0 }
                , { name: '垂直行业', v: 1 }
                , { name: '地方门户', v: 2 }
                , { name: '影音游戏', v: 3 }
                , { name: '商城购物', v: 4 }
                , { name: '时尚女性', v: 5 }
                , { name: '文学小说', v: 6 }
                , { name: '生活服务', v: 7 }
                , { name: '其他', v: 8 }
            ]
            , bdr: [
                { name: '全部', all: true, active: true, v: -1 }
                , { name: 'BDR0', v: 0 }
                , { name: 'BDR1', v: 1 }
                , { name: 'BDR2', v: 2 }
                , { name: 'BDR3', v: 3 }
                , { name: 'BDR4', v: 4 }
                , { name: 'BDR5', v: 5 }
                , { name: 'BDR6', v: 6 }
                , { name: 'BDR7', v: 7 }
                , { name: 'BDR8', v: 8 }
                , { name: 'BDR9', v: 9 }
            ]
            , pr: [
                { name: '全部', all: true, active: true, v: -1 }
                , { name: 'PR0', v: 0 }
                , { name: 'PR1', v: 1 }
                , { name: 'PR2', v: 2 }
                , { name: 'PR3', v: 3 }
                , { name: 'PR4', v: 4 }
                , { name: 'PR5', v: 5 }
                , { name: 'PR6', v: 6 }
                , { name: 'PR7', v: 7 }
                , { name: 'PR8', v: 8 }
                , { name: 'PR9', v: 9 }
                , { name: 'PR10', v: 10 }
            ]
            , ipNum: [
                { name: '全部', all: true, active: true, v: 0 }
                , { name: '≤100IP', v: 1 }
                , { name: '≤500IP', v: 2 }
                , { name: '≤1000IP', v: 3 }
                , { name: '≤3000IP', v: 4 }
                , { name: '≤5000IP', v: 5 }
                , { name: '≤10000IP', v: 6 }
                , { name: '>10000IP', v: 7 }
            ]
            , fee: [
                { name: '全部', all: true, active: true, v: 0 }
                , { name: '一万以内', v: 1, min: 0, max: 999999 }
                , { name: '1万-3万', v: 2, min: 1000000, max: 2999999 }
                , { name: '3万-5万', v: 3, min: 3000000, max: 4999999 }
                , { name: '5万-10万', v: 4, min: 5000000, max: 9999999 }
                , { name: '10万-20万', v: 5, min: 10000000, max: 19999999 }
                , { name: '20万-30万', v: 6, min: 20000000, max: 29999999 }
                , { name: '30万以上', v: 7, min: 30000000 }
            ]
            , bInclude: [{}]
            , income: [{}]
            , ms: [{}]
            , adSource: [{}]
        }, query: {}
    });

    const [queryBdr, setQueryBdr] = useState({ min: undefined, max: undefined });
    const [queryFee, setQueryFee] = useState({ min: undefined, max: undefined });

    useEffect(() => {
        props.clickFilter(state.query);
    }, [state.query]);

    const filter = (e) => {
        let query = { ...state.query };
        let queryType = e.currentTarget.getAttribute('data_query_type');
        let queryValue = e.currentTarget.getAttribute('data_query_value');
        switch (queryType) {
            case 'web_type':
                const newWebType = state.item.webType.map(value => ({
                    ...value, active: value.v == queryValue
                }));
                query.webType = queryValue;
                if (queryValue === '0') {
                    delete query.webType;
                }
                setState({ item: { ...state.item, webType: newWebType }, query: query });
                break;
            case 'bdr':
                const newBdr = state.item.bdr.map(value => ({
                    ...value, active: value.v == queryValue
                }));
                query.bdrMin = queryValue;
                if (queryValue === '-1') {
                    delete query.bdrMin;
                }
                setState({ item: { ...state.item, bdr: newBdr }, query: query });
                setQueryBdr({ max: undefined, min: undefined });
                break;
            case 'pr':
                const newPr = state.item.pr.map(value => ({
                    ...value, active: value.v == queryValue
                }));
                query.pr = queryValue;
                if (queryValue === '-1') {
                    delete query.pr;
                }
                setState({ item: { ...state.item, pr: newPr }, query: query });
                break;
            case 'ip_num':
                const newIpNum = state.item.ipNum.map(value => ({
                    ...value, active: value.v == queryValue
                }));
                query.ipNum = queryValue;
                if (queryValue === '0') {
                    delete query.ipNum;
                }
                setState({ item: { ...state.item, ipNum: newIpNum }, query: query });
                break;
            case 'fee':
                const newFee = state.item.fee.map(value => ({
                    ...value, active: value.v == queryValue
                }));
                const queryFee = newFee.filter(value => value.active && value.active === true)[0];
                query.feeMin = queryFee.min;
                switch (queryValue) {
                    case '0':
                        delete query.feeMin;
                        delete query.feeMax;
                        break;
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '5':
                    case '6':
                        query.feeMax = queryFee.max;
                        break;
                    case '7':
                        delete query.feeMax;
                        break;
                }
                setState({ item: { ...state.item, fee: newFee }, query: query });
                setQueryFee({ max: undefined, min: undefined });
                break;
        }
    }

    //清除所有条件
    const cleanAll = () => {
        const item = { ...state.item };
        const newItem = {};
        Object.keys(item).map(key => (
            newItem[key] = item[key].map(value =>
                ({ ...value, active: value.all ? true : false })
            )
        ));
        setState({ ...state, item: { ...newItem, bInclude: [{}], income: [{}], ms: [{}], adSource: [{}] }, query: {} });
        setQueryFee({});
        setQueryBdr({});
    }

    //百度收录量
    const bIncludeChange = (value, option) => {
        let query = { ...state.query };
        let bInclude = { v: value, name: option.children, active: true };
        if (value === '0') {
            delete query.bInclude;
            bInclude = {};
        } else {
            query.bInclude = value;
        }
        setState({ ...state, item: { ...state.item, bInclude: [bInclude] }, query: query });
    };

    //是否有收入
    const incomeChange = (value, option) => {
        let query = { ...state.query };
        let income = { v: value, name: option.children, active: true };
        if (value === '0') {
            delete query.income;
            income = {};
        } else {
            query.income = value;
        }
        setState({ ...state, item: { ...state.item, income: [income] }, query: query });
    }
    //是否有收入
    const msChange = (value, option) => {
        let query = { ...state.query };
        let ms = { v: value, name: option.children, active: true };
        if (value === '0') {
            delete query.ms;
            ms = {};
        } else {
            query.ms = value;
        }
        setState({ ...state, item: { ...state.item, ms: [ms] }, query: query });
    }
    //是否有收入
    const adSourceChange = (value, option) => {
        let query = { ...state.query };
        let adSource = { v: value, name: option.children, active: true };
        if (value === '0') {
            delete query.adSource;
            adSource = {};
        } else {
            query.adSource = value;
        }
        setState({ ...state, item: { ...state.item, adSource: [adSource] }, query: query });
    }
    //官方代售
    const isOfficial = (e) => {
        let query = { ...state.query };
        if (e.target.checked) {
            query.sellType = 1;
        } else {
            delete query.sellType;
        }
        setState({ ...state, query: query });
    }
    //排序
    const orderChange = (e, value) => {
        let queryValue = state.query.order ? state.query.order : value;
        let element = e.target.parentNode.children;
        for (let i = 0; i < element.length; i++) {
            element[i].className = '';
        }
        e.target.className = 'site-filter-this';
        if (value === queryValue) {
            value++;
        }
        setState({ ...state, query: { ...state.query, order: value } });
    }
    const bdrChangeMin = (value) => {
        if (value < 0 || value > 9) {
            value = undefined;
        }
        setQueryBdr({ ...queryBdr, min: value })
    }
    const bdrChangeMax = (e) => {
        let query = { ...state.query };
        let bdrMax = parseInt(e.target.value);
        let bdrMin = queryBdr.min ? queryBdr.min : 0;
        let bdr = state.item.bdr.map(value => ({
            ...value, active: value.all && !bdrMax
        }));
        if (bdrMax) {
            if (bdrMax < bdrMin) {
                bdrMax = ++bdrMin;
            } else if (bdrMax > 9) {
                bdrMax = 9;
            }
            query.bdrMin = bdrMin;
            query.bdrMax = bdrMax;
        } else {
            bdrMax = undefined;
        }
        setQueryBdr({ ...queryBdr, min: bdrMin, max: bdrMax });
        setState({ ...state, item: { ...state.item, bdr: bdr }, query: query });
    }

    const feeChangeMin = (value) => {
        if (value < 0) {
            value = 0;
        }
        setQueryFee({ ...queryFee, min: value })
    }
    const feeChangeMax = (e) => {
        let query = { ...state.query };
        let feeMax = parseInt(e.target.value);
        let feeMin = queryFee.min ? queryFee.min : 0;
        let fee = state.item.fee.map(value => ({
            ...value, active: value.all && !feeMax
        }));
        if (feeMax) {
            if (feeMax < feeMin) {
                feeMax = feeMin;
            }
            query.feeMin = feeMin * 100;
            query.feeMax = feeMax * 100;
        } else {
            feeMax = undefined;
        }
        setQueryFee({ ...queryFee, min: feeMin, max: feeMax });
        setState({ ...state, item: { ...state.item, fee: fee }, query: query });
    }
    return (
        <>
            <Row className='hue-bg-white'>
                <Col className="site-filter-tb" span={24}>
                    <Row align='middle' wrap={false}>
                        <Col lg={3} xl={2} xs={4}> 网站筛选 </Col>
                        <Col lg={21} xl={22} xs={20}>
                            <Space>
                                <span>您已选择：</span>
                                {Object.keys(state.item).map(key => (
                                    state.item[key].map(value => (
                                        value.active && !value.all && <span key={key + value.v} className="site-filter-item">{value.name} <i className="layui-icon layui-icon-close"></i></span>
                                    ))
                                ))}
                                {queryBdr.max && <span className="site-filter-item">{'BDR' + queryBdr.min + '~BDR' + queryBdr.max} <i className="layui-icon layui-icon-close"></i></span>}
                                {queryFee.max && <span className="site-filter-item">{'￥' + queryFee.min + '~￥' + queryFee.max} <i className="layui-icon layui-icon-close"></i></span>}
                                <span onClick={cleanAll}>清空选择</span>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col className="site-filter-items" span={24}>
                    <Row align='middle'>
                        <Col lg={3} xl={2} xs={24}>网站类型： </Col>
                        <Col lg={21} xl={22} xs={24}>
                            {state.item.webType.map(value => (
                                <span data_query_type='web_type' data_query_value={value.v} key={value.v} onClick={filter} className={value.active ? 'site-filter-this' : ''}>{value.name}</span>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col className="site-filter-items" span={24}>
                    <Row align='middle'>
                        <Col lg={3} xl={2} xs={24}>百度权重： </Col>
                        <Col lg={21} xl={22} xs={24}>
                            {state.item.bdr.map(value => (
                                <span data_query_type='bdr' data_query_value={value.v} key={value.v} onClick={filter} className={value.active ? 'site-filter-this' : ''}>{value.name}</span>
                            ))}
                            <Input.Group compact size='small'>
                                <InputNumber placeholder="BDR0" controls={false} addonAfter="~" min={0} max={8} style={{ width: 93 }} size="small" onChange={bdrChangeMin} value={queryBdr.min && queryBdr.min} />
                                <Tooltip title="回车确认" color="red" placement='right'>
                                    <InputNumber placeholder="BDR9" className="site-input-right" min={1} max={9} controls={false} style={{ width: 60 }} size="small" onPressEnter={bdrChangeMax} value={queryBdr.max && queryBdr.max} />
                                </Tooltip>
                            </Input.Group>
                        </Col>
                    </Row>
                </Col>
                <Col className="site-filter-items" span={24}>
                    <Row align='middle'>
                        <Col lg={3} xl={2} xs={24}>PR&ensp;&emsp;值： </Col>
                        <Col lg={21} xl={22} xs={24}>
                            {state.item.pr.map(value => (
                                <span data_query_type='pr' data_query_value={value.v} key={value.v} onClick={filter} className={value.active ? 'site-filter-this' : ''}>{value.name}</span>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col className="site-filter-items" lg={24}>
                    <Row align='middle'>
                        <Col lg={3} xl={2} xs={24}> 网络流量： </Col>
                        <Col lg={21} xl={22} xs={24}>
                            {state.item.ipNum.map(value => (
                                <span data_query_type='ip_num' data_query_value={value.v} key={value.v} onClick={filter} className={value.active ? 'site-filter-this' : ''}>{value.name}</span>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Col lg={24} className="site-filter-items">
                    <Row align='middle'>
                        <Col lg={3} xl={2}> 网站价格： </Col>
                        <Col lg={21} xl={22}>
                            {state.item.fee.map(value => (
                                <span data_query_type='fee' data_query_value={value.v} key={value.v} onClick={filter} className={value.active ? 'site-filter-this' : ''}>{value.name}</span>
                            ))}
                            <Input.Group compact size='small'>
                                <InputNumber placeholder="￥" controls={false} addonAfter="~" min={0} style={{ width: 113 }} size="small" onChange={feeChangeMin} value={queryFee.min && queryFee.min} />
                                <Tooltip title="回车确认" color="red" placement='right'>
                                    <InputNumber className="site-input-right" controls={false} placeholder="￥" style={{ width: 80 }} size="small" onPressEnter={feeChangeMax} value={queryFee.max && queryFee.max} />
                                </Tooltip>
                            </Input.Group>
                        </Col>
                    </Row>
                </Col>
                <Col lg={24} xs={24} className="site-filter-items-select">
                    <Row align='middle'>
                        <Col lg={3} xl={2}><span>其他条件：</span></Col>
                        <Col lg={21} xl={22}>
                            <Space wrap={true}>
                                <Select size='small' placeholder='百度收录量' onChange={bIncludeChange} value={state.item.bInclude[0].v}>
                                    <Option value="0">不限</Option>
                                    <Option value="1">1万以下</Option>
                                    <Option value="2">1万至10万</Option>
                                    <Option value="3">10万至50万</Option>
                                    <Option value="4">50万至100万</Option>
                                    <Option value="5">100万以上</Option>
                                </Select>
                                <Select size='small' placeholder='是否有收入' onChange={incomeChange} value={state.item.income[0].v}>
                                    <Option value="0">不限</Option>
                                    <Option value="1">无收入</Option>
                                    <Option value="2">有收入</Option>
                                </Select>
                                <Select size='small' placeholder='是否为秒收' onChange={msChange} value={state.item.ms[0].v}>
                                    <Option value="0">不限</Option>
                                    <Option value="1">百度秒收</Option>
                                    <Option value="2">360秒收</Option>
                                    <Option value="3">二者皆有</Option>
                                </Select>
                                <Select size='small' placeholder='是否有新闻源' onChange={adSourceChange} value={state.item.adSource[0].v}>
                                    <Option value="0">不限</Option>
                                    <Option value="1">百度新闻源</Option>
                                    <Option value="2">360新闻源</Option>
                                    <Option value="3">二者皆有</Option>
                                </Select>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col className="site-filter-more" lg={24} xs={24}>
                    <Row align='middle'>
                        <Col lg={10} xs={24}>
                            <div className="site-filter-btn-left">
                                <span className="site-filter-this" onClick={(e) => { orderChange(e, 1) }}>发布时间</span>
                                <span onClick={(e) => { orderChange(e, 3) }}>价格</span>
                                <span onClick={(e) => { orderChange(e, 5) }}>百度权重</span>
                            </div>
                        </Col>
                        <Col lg={{ span: 6, offset: 8 }} xl={{ span: 5, offset: 9 }} xxl={{ span: 4, offset: 10 }} xs={0} className="site-filter-btn-right">
                            <Checkbox onChange={isOfficial}>只显示<span className="comm-red">【官方代售】</span></Checkbox>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
export default SitesFilter;