import { BASE_URL } from "src/config/config";
import errorHandler, { ResponseStructure } from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";

/**
 * 用户购物车
 * @author minghue
 * @data 2022年12月19日
 */
export async function cartAdd(data: ADO.userCartItem) {
    return requestX("PUT", BASE_URL.url1 + "/user/cart", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const msg = res as unknown as ResponseStructure;
            if (msg.success) {
                return msg;
            } else {
                return errorHandler(msg);
            }
        }).catch(e => {
            console.error(e);
        })
}
/**
 * 列表
 * @param params 参数
 * @returns 
 */
export async function cartList() {
    return requestX("GET", BASE_URL.url1 + "/user/cart/list")
        .then(request => request.json()).then(res => {
            const list = res as unknown as ADO.userCartItems;
            if (list.success) {
                return list;
            } else {
                return errorHandler(list) as ADO.userCartItems;
            }
        }).catch(e => {
            console.error(e);
        })
}