import { ADO } from "@/services/api/typings";

//token 封装
const TOKEN_KEY = "token";
const USER_INFO = "userInfo";

// token
function tokenSet(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
}

function tokenGet() {
    return localStorage.getItem(TOKEN_KEY);
}

function tokenRemove() {
    localStorage.removeItem(TOKEN_KEY);
}
// 用户
function userInfoSet(userInfo: ADO.userInfo | undefined) {
    localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
}

function userInfoGet(): ADO.userInfo | undefined {
    const info = localStorage.getItem(USER_INFO);
    if (info) {
        return JSON.parse(info) as ADO.userInfo;
    } else {
        return;
    }
}

function userInfoRemove() {
    localStorage.removeItem(USER_INFO);
}

export {
    tokenSet, tokenGet, tokenRemove, userInfoGet, userInfoRemove, userInfoSet
}