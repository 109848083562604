import { Col, Row } from 'antd';
import SitesList from './List';
import SitesChat from './Chat';
import SitesInfo from './Info';
import "./sites.less";
import { Route, Routes } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { ADO } from 'src/services/api/typings';

const Sites = () => {

    return (
        <>
            <Row>
                <Col lg={24} xs={0} className="nav-top-img" style={{ backgroundImage: 'url("/img/sites/nav-top-img.jpg")' }} />
            </Row>
            <Row justify="center" gutter={[0, 8]} className='ctn-box'>
                <Routes>
                    <Route index element={<SitesList convert={siteConvert} />} />
                    <Route path='/:num' element={<SitesInfo convert={siteConvert} />} />
                </Routes>
                {/* 联系客服 */}
                <SitesChat />
            </Row>
        </>
    );
}

const siteConvert = (data: ADO.website) => {
    let webType = data.webType, ipNum = data.ipNum, webApp = data.webApp, phoneSite = data.phoneSite, webServerSell = data.webServerSell, stateAd = data.stateAd, adSource = data.adSource, stateMs = data.stateMs;
    if (webType) {
        if (webType == 1) {
            webType = "垂直行业";
        } else if (webType == 2) {
            webType = "地方门户";
        } else if (webType == 3) {
            webType = "影音游戏";
        } else if (webType == 4) {
            webType = "商城购物";
        } else if (webType == 5) {
            webType = "时尚女性";
        } else if (webType == 6) {
            webType = "文学小说";
        } else if (webType == 7) {
            webType = "生活服务";
        } else if (webType == 8) {
            webType = "其他";
        }
    }
    if (ipNum) {
        if (ipNum == 1) {
            ipNum = "≤100IP";
        } else if (ipNum == 2) {
            ipNum = "≤500IP";
        } else if (ipNum == 3) {
            ipNum = "≤1000IP";
        } else if (ipNum == 4) {
            ipNum = "≤3000IP";
        } else if (ipNum == 5) {
            ipNum = "≤5000IP";
        } else if (ipNum == 6) {
            ipNum = "≤10000IP";
        } else if (ipNum == 7) {
            ipNum = ">10000IP";
        }
    }
    if (webApp) {
        switch (webApp) {
            case 1:
                webApp = 'Dede织梦';
                break;
            case 2:
                webApp = '帝国Cms';
                break;
            case 3:
                webApp = '蚂蚁Cms';
                break;
            case 4:
                webApp = '苹果Cms';
                break;
            case 5:
                webApp = '杰奇';
                break;
            case 6:
                webApp = 'ZBlog';
                break;
            case 7:
                webApp = 'Discuz';
                break;
            case 8:
                webApp = 'WordPress';
                break;
            case 9:
                webApp = 'PHP';
                break;
            case 10:
                webApp = 'ASP';
                break;
            case 11:
                webApp = '.NET';
                break;
            case 12:
                webApp = 'JAVA';
                break;
            case 12:
                webApp = '自研程序';
                break;
            default:
                webApp = '其他';
        }
    }
    if (phoneSite) {
        switch (phoneSite) {
            case 0:
                phoneSite = '-';
            case 1:
                phoneSite = '有';
            case 2:
                phoneSite = '无';
        }
    }
    if (webServerSell) {
        switch (webServerSell) {
            case 0:
                webServerSell = '不转让';
            case 1:
                webServerSell = '转让';
            case 2:
                webServerSell = '可商议';
        }
    }
    if (stateAd) {
        switch (stateAd) {
            case 0:
                stateAd = '-';
            case 1:
                stateAd = '有';
            case 2:
                stateAd = '无';
        }
    }
    if (adSource) {
        switch (adSource) {
            case 0:
                adSource = '-';
            case 1:
                adSource = '百度';
            case 2:
                adSource = '360';
            case 3:
                adSource = '百度+360';
        }
    }
    if (stateMs) {
        switch (adSource) {
            case 0:
                adSource = '-';
            case 1:
                adSource = '百度';
            case 2:
                adSource = '360';
            case 3:
                adSource = '百度+360';
        }
    }
    return { ...data, webType: webType, ipNum: ipNum, fee: BigNumber(data.fee ? data.fee : 0).multipliedBy(0.01).toFixed(2), webApp: webApp, phoneSite: phoneSite, stateAd: stateAd, adSource: adSource }
}
export default Sites;
export { siteConvert }