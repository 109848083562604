import { Button, Result, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoFoundPage: React.FC = () => {

    const navigage = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="抱歉，你要找的页面不存在了"
            extra={
                <Space>
                    <Button type="primary" onClick={() => navigage('/')}>
                        返回首页
                    </Button>
                </Space>
            }
        />
    )
};

export default NoFoundPage;
