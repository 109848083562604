import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";
import { GoodsType } from "../enums";

/**
 * 
 * @param params 参数
 * @returns  获取结算商品基础信息
 */
export async function getBaseInfo(data: {
    goodsType: GoodsType,
    no: number
}): Promise<ADO.tradeResult> {
    return requestX("GET", BASE_URL.url1 + "/user/trade", data)
        .then(request => request.json()).then(res => {
            const list = res as unknown as ADO.tradeResult;
            if (list.success) {
                return list;
            } else {
                return errorHandler(list) as ADO.tradeResult;
            }
        })
}
