const commonConfig = {
    version: '1.0.0'//版本
    , env: "prod"//环境
}

const env = {
    "dev": {//work
        "url1": "http://192.168.2.216:20001"//后台user-api地址
        , "url2": "http://192.168.1.15:3001"
        , "img1": "https://chuangyiniao-dev.oss-cn-guangzhou.aliyuncs.com/"//oss存储
        , "img2": "/img/"//临时：本地》》上线后替换为img1
    },
    "devs": {//home
        "url1": "http://127.0.0.1:8080"
        , "url2": "http://192.168.1.15:3002"
        , "img1": ""
        , "img2": "https://chuangyiniao-dev.oss-cn-guangzhou.aliyuncs.com/"//临时：本地》》上线后替换为img1
    },
    "prod": {
        "url1": "https://cyn-api.minghue.com/cyn_web_dev"
        , "url2": "http://192.168.1.15:3002"
        , "img1": "https://chuangyiniao-dev.oss-cn-guangzhou.aliyuncs.com/"
        , "img2": "/img/"
    }
}

// export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseURL: proBaseURL;

export const TIMEOUT = 5000;

export const BASE_URL = { ...env[commonConfig.env] }
