import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";


/**
 * 列表
 * @param data 
 * @returns 
 */
export async function mineList(data: { current?: number, pageSize?: number }): Promise<ADO.ResultPage<ADO.website>> {
    return requestX("GET", BASE_URL.url1 + "/user/goods/site/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<ADO.website>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultPage<ADO.website>;
            }
        })
}
/**
 * 推荐列表
 * @param id 
 * @returns 
 */
export async function recommend(id: any): Promise<ADO.ResultMessageT<Array<ADO.website>>> {
    return requestX("GET", BASE_URL.url1 + "/goods/site/recommend/" + id).then(request => request.json())
}

/**
 * 详情
 * @param num
 * @returns 
 */
export async function info(num: string): Promise<ADO.ResultMessageT<ADO.website>> {
    return requestX('GET', BASE_URL.url1 + '/goods/site/' + num).then(request => request.json())
}