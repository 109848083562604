import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";
import { GlobalSettingType } from "../enums";

/**
 * 列表
 * @param data 
 * @returns 
 */
export async function list(data: { type: GlobalSettingType, pageSize: number }): Promise<ADO.ResultPage<any>> {
    return requestX("GET", BASE_URL.url1 + "/global/setting/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<any>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result, "/brand", false) as ADO.ResultPage<ADO.brandRegister>;
            }
        })
}