import { BASE_URL } from "src/config/config";
import errorHandler, { ResponseStructure } from "src/requestErrorConfig";
import { request, requestX } from "src/utils/API";
import { ADO } from "./typings";
import { tokenRemove, userInfoRemove, userInfoSet } from "@/utils/token";

/**
 * 用户登录
 * @param data 
 * @returns 
 */
export async function login(data: ADO.userLogin) {
    return request("POST", BASE_URL.url1 + "/login", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json());
}

/**
 * 注册
 * @param data 
 * @returns 
 */
export async function signup(data: ADO.userLogin) {
    return request("POST", BASE_URL.url1 + "/signup", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ResponseStructure;
            if (data.success) {
                return res;
            } else {
                return errorHandler(data);
            }
        }).catch(e => {
            console.error(e);
        });
}

/**
 * 
 * @returns 用户基本信息
 */
export async function getInfo(): Promise<ADO.ResultMessageT<ADO.userInfo>> {
    return requestX("GET", BASE_URL.url1 + "/user/info")
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessageT<ADO.userInfo>;
            if (data.success) {
                userInfoSet(data.data);
                return res;
            } else {
                return errorHandler(data, '/user');
            }
        }).catch(e => {
            console.error(e);
        });
}

/**
 * 修改用户基本信息
 */
export async function update(data: ADO.userInfo): Promise<ADO.ResultMessageT<ADO.userInfo>> {
    return requestX("PATCH", BASE_URL.url1 + "/user", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const data = res as unknown as ADO.ResultMessageT<ADO.userInfo>;
            if (data.success) {
                userInfoSet(data.data);
                return res;
            } else {
                return errorHandler(data, '/user/system');
            }
        }).catch(e => {
            console.error(e);
        });
}

/**
 * 退出登录
 */
export async function logOut(): Promise<void> {
    return requestX("POST", BASE_URL.url1 + "/user/logout")
        .then(request => request.json()).then(res => {
            userInfoRemove();
            tokenRemove();
            window.location.href = "/";
        })
}