import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Divider, Space } from 'antd';
import { ADO } from 'src/services/api/typings';
import api from 'src/services/api';

const NewsInfo = () => {
    const [info, setInfo] = useState<ADO.news>();
    const params = useParams();

    useEffect(() => {
        params.id && api.news.info(params.id).then(res => {
            setInfo(res.data);
        })
    }, [params.id]);

    return (
        <>
            <Row gutter={{ lg: 8 }} justify="center" id='news-info-box'>
                <Col span={24}>
                    <h1>{info?.title}</h1>
                </Col>
                <Col span={24}>
                    <Space size={'large'}>
                        <span>发布时间：{info?.timeAdd?.substring(0, 10)}</span>
                        <span>浏览人数：{info?.views}</span>
                    </Space>
                </Col>
                <Col span={24}>
                    <Divider />
                    <div dangerouslySetInnerHTML={{ __html: info?.content }} className='news-info-box-content' />
                </Col>
            </Row>
        </>
    );
}

export default NewsInfo;