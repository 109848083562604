import React, { useEffect, useState } from 'react';
import { Button, Col, Space, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';
import { GoodsType } from 'src/services/enums';

interface TableParams {
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue>,
    sorter?: SorterResult<ADO.brandRegister>
}
/**
 * 企业服务
 * @returns 
 */
const UserEmployer: React.FC = () => {
    const [data, setData] = useState<ADO.employer[]>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const columns: ColumnsType<ADO.brandRegister> = [
        {
            title: '类型',
            dataIndex: 'title',
        },
        {
            title: '价格',
            dataIndex: 'price',
            render: (value) => value ? '￥' + BigNumber(value).dividedBy(100).toFixed(2) : '-'
        },
        {
            title: '状态',
            dataIndex: 'state',
            render: (value, record) => {
                switch (value) {
                    case 0:
                        if (record.auditState === 3) {
                            return '审核：不通过，原因：' + record.auditFailReason
                        } else if (record.auditState === 2) {
                            return '审核：通过';
                        } else {
                            return '等待审核中';
                        }
                    case 1:
                        return '等待支付';
                    case 2:
                        return '交接中';
                    case 3:
                        return '完成';
                    default:
                        return '-'
                }
            }
        },
        {
            title: '添加时间',
            dataIndex: 'timeAdd',
            render: (value) => value.substring(0, 16)
        },
        {
            title: '说明'
            , dataIndex: 'introduce'
        },
        {
            title: '操作'
            , key: 'action'
            , render: (_, record) => (
                <Space >
                    {record.state === 1 && <Button type='link' className="hue-blue" onClick={() => navigate("/trade", { state: { goodsType: GoodsType.employer, no: record.id } })}>支付</Button>}
                    {record.auditState != 0 && <Button type='link' className="hue-blue">客服</Button>}
                    {record.state === 0 && <Button type='link' className="hue-danger">删除</Button>}
                </Space>
            )
        }
    ];

    const fetchData = () => {
        setLoading(true);
        api.employer.list({ ...tableParams.pagination }).then(res => {
            setData(res.records);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
        })
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<ADO.vouch>,
    ) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <Col span={24}>
            <Table
                columns={columns}
                rowKey={(record) => record.id ? record.id : 0}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </Col>
    );
};

export default UserEmployer;