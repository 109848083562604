import { userInfoGet } from "@/utils/token";
import { MinusOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Descriptions, Typography, Col, Table, Tabs, TabsProps, Tooltip } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "src/services/api";
import { ADO } from "src/services/api/typings"

const { Text } = Typography;

export default (params: any) => {

    const locationState: any = useLocation().state;

    const userInfo = userInfoGet();


    const items: TabsProps['items'] = [
        {
            key: 'walletbill',
            label: `流水记录`,
            children: <WalletBillTable />,
        },
        {
            key: 'recharge',
            label: `充值&提现记录`,
            children: <RechargeTable />,
        },

    ];
    const onChange = (key: string) => {
        /*if (key !== 'all') {
            getList(Number(key));
        } else {
            getList();
        }*/
        setActiveKey(key);
    };

    const [activeKey, setActiveKey] = useState((locationState && locationState.recharge) ? items[1].key : items[0].key);
    return (
        <>
            <Col span={24}>
                <Descriptions title={<img width={60} src="/img/common/user-log.png" alt="user-log" />} column={2}>
                    <Descriptions.Item label="会员名">{userInfo?.name}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{userInfo?.tel ? userInfo.tel : '未填写'}</Descriptions.Item>
                    <Descriptions.Item label="实名认证">{userInfo?.state === 2 ? '已认证' : '未认证'}</Descriptions.Item>
                    <Descriptions.Item label="账户余额"><Text type="warning" strong>￥{BigNumber(userInfo?.balance ? userInfo.balance : 0).multipliedBy(0.01).toFixed(2)}</Text></Descriptions.Item>
                    <Descriptions.Item label="冻结金额"><Text type="success" strong>￥{BigNumber(userInfo?.blocking ? userInfo.blocking : 0).multipliedBy(0.01).toFixed(2)}</Text></Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <Tabs activeKey={activeKey} items={items} onChange={onChange} />
            </Col>
        </>
    )
}

const WalletBillTable = () => {

    const [data, setData] = useState<ADO.userWalletBill[]>();
    const [tableParams, setTableParams] = useState<{ pagination: TablePaginationConfig }>({
        pagination: {
            current: 1,
            pageSize: 10
        },
    });

    useEffect(() => {
        getList(tableParams.pagination)
    }, []);

    const columns: ColumnsType<ADO.userWalletBill> = [
        {
            title: '时间',
            dataIndex: 'timeAdd',
            key: 'timeAdd',
            render: (text) => {
                return text.substring(0, 16)
            }
        },
        {
            title: '单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            responsive: ['lg']
        },
        {
            title: '金额',
            dataIndex: 'fee',
            key: 'fee',
            render: (text, record) => {
                if (record.isIncome) {
                    return <><PlusOutlined style={{ color: 'red' }} /><span>￥{BigNumber(text).multipliedBy(0.01).toFixed(2)}</span></>;
                } else {
                    return <><MinusOutlined style={{ color: 'green' }} /><span>￥{BigNumber(text).multipliedBy(0.01).toFixed(2)}</span></>;
                }
            }
        }, {
            title: '说明',
            dataIndex: 'dataType',
            key: 'dataType',
            render: (text) => {
                if (text === 10) {
                    return '支付';
                } else if (text === 20) {
                    return '冻结资金';
                } else if (text === 21) {
                    return '资金解冻';
                } else if (text === 30) {
                    return '充值';
                } else if (text === 40) {
                    return '提现';
                } else {
                    return '-';
                }
            }
        }, {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            responsive: ['lg']
        }
    ];

    const handleTableChange = (
        pagination: TablePaginationConfig,
    ) => {
        setTableParams({
            pagination,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
        getList({ current: pagination.current ? pagination.current : 1, pageSize: pagination.pageSize });
    };

    const getList = (data: TablePaginationConfig) => {
        api.userWalletBill.list(data).then(res => {
            setData(res.records);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                    current: data.current,
                    pageSize: data.pageSize
                }
            })
        });
    }
    return (
        <Table dataSource={data} columns={columns} size="small" pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange} />
    )
}

/**
 * 充值、提现记录组件
 * @returns 
 */
const RechargeTable = () => {

    const [data, setData] = useState<ADO.userRecharge[]>();
    const [tableParams, setTableParams] = useState<{ pagination: TablePaginationConfig }>({
        pagination: {
            current: 1,
            pageSize: 10
        },
    });

    useEffect(() => {
        getList(tableParams.pagination)
    }, [])

    const column: ColumnsType<ADO.userRecharge> = [
        {
            title: '时间',
            dataIndex: 'timeAdd',
            key: 'timeAdd',
            render: (text) => {
                return text.substring(0, 16)
            }
        },
        {
            title: '类型',
            dataIndex: 'isWithdraw',
            key: 'isWithdraw',
            render: (text) => {
                if (text) {
                    return '提现';
                } else {
                    return '充值';
                }
            }
        }, {
            title: '金额',
            dataIndex: 'money',
            key: 'money',
            render: (text) => {
                return <>￥{BigNumber(text).multipliedBy(0.01).toFixed(2)}</>
            }
        }, {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            responsive: ['lg'],
            render: (text, record) => {
                if (text === 1) {
                    return '支付宝';
                } else if (text === 2) {
                    return '微信';
                } else {
                    return '银行卡';
                }
            }
        }, {
            title: '账户',
            dataIndex: 'account',
            key: 'account',
            responsive: ['lg'],
            render: (text, record) => {
                if (record.payType === 3) {
                    return record.userBankAccountNum;
                } else {
                    return text;
                }
            }
        }, {
            title: '审核状态',
            dataIndex: 'checkState',
            key: 'checkState',
            render: (text, record) => {
                if (text === 1) {
                    return '等待审核';
                } else if (text === 2) {
                    return '通过';
                } else {
                    return (
                        <>
                            失败{record.checkFailReason ? '：' + record.checkFailReason : ''}&emsp;
                            <Tooltip title="有疑问？点击联系客服" color="#7F7F7F">
                                <QuestionCircleOutlined className="hue-gray" onClick={() => window.open('http://wpa.qq.com/msgrd?v=3&uin=1558326475&site=qq&menu=yes', '_blank')} />
                            </Tooltip>
                        </>
                    )
                }
            }
        }
    ];

    const handleTableChange = (
        pagination: TablePaginationConfig,
    ) => {
        /*setTableParams({
            pagination,
        });*/
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
        getList({ current: pagination.current ? pagination.current : 1, pageSize: pagination.pageSize });
    };

    const getList = (data: TablePaginationConfig) => {
        api.userRecharge.list(data).then(res => {
            setData(res.records);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                    current: data.current,
                    pageSize: data.pageSize
                }
            })
        });
    }
    return (
        <Col span={24}>
            <Table dataSource={data} columns={column} size="small" pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange} />
        </Col>
    )
}