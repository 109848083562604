import './App.less';
import { Head, Footer, Home, Brand, Employer, Login, News, Sites, Vouch, User, Trade, About } from "./pages"
import {
    Route,
    Routes,
} from 'react-router-dom';
import OrderInfo from './pages/OrderInfo';
import AuthRoute from '@/compontens/AuthRoute';
import NoFoundPage from '@/compontens/NoFoundPage';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd';

function App() {
    return (
        <div id="App" className="App">
            <Routes>
                <Route index element={<Home />} />
                <Route element={<Head />}>
                    <Route path="brand" element={<Brand />} />
                    <Route path="employer" element={<Employer />} />
                    <Route path="news/*" element={<News />} />
                    <Route path="sites/*" element={<Sites />} />
                    <Route path="vouch" element={<Vouch />} />
                    <Route path="login" element={<Login />} />
                    <Route path="user/order/detail/:no" element={<OrderInfo />} />
                    <Route path="user/*" element={<AuthRoute><User /></AuthRoute>} />
                    <Route path="trade/*">
                        <Route index element={<Trade />} />
                        <Route path=':goodsType/:no' element={<Trade />} />
                        <Route path="*" element={<NoFoundPage />} />
                    </Route>
                    <Route path="about" element={<About />} />
                    <Route path="*" element={<NoFoundPage />} />
                </Route>
            </Routes >
            <Footer />
        </div >
    );
}

export default App;