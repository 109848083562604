import { message } from 'antd';
import { tokenRemove } from './utils';

// 错误处理方案： 错误类型
enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}
// 与后端约定的响应数据格式
interface ResponseStructure {
  success: boolean;
  code: number;
  data: any;
  message: string;
  sign: string;
  errorCode?: number;
  errorMessage?: string;
  showType?: ErrorShowType;
}

// 与后端约定的响应数据格式
interface ResponseList {
  records: [],
  total: number,
  size: number,
  pages: number,
}

/**
 * 处理错误请求
 * @param response 请求响应
 * @param callback 登录成功后跳转地址，用于未登录情况
 * @param showError 是否显示错误信息
 * @returns 
 */
const errorHandler = (response: any, callback?: string, showError: boolean = false) => {
  // 拦截响应数据，进行个性化处理
  const data = response as unknown as ResponseStructure;
  if (data?.success === false) {
    if (data.code === 999999) {
      tokenRemove();
      localStorage.removeItem("userInfo");
      message.error({
        duration: 1,
        content: '还未登录，正在跳转登录。',
        onClose: () => { window.location.href = callback ? "/login?callback=" + encodeURIComponent(callback) : "/login"; }
      });
      return;
    } else if (data.code === 404) {
      window.location.href = "/404";
    } else if (showError) {
      message.error(data.message ? data.message : '请求失败！');
    }
  }
  return response;
}


export default errorHandler;
export { ResponseStructure, ResponseList };