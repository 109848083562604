import MineError from "./MineError";
import { tokenGet, tokenRemove } from "./token";

/**
 *
 * @param {*} url
 * @param {*} type GET、HEAD、POST、PUT、DELETE、OPTIONS、TRACE、PATCH
 * @param {*} data
 * @param {*} needAuth 是否需要token，true需要false如果localStorage有数据则上传
 * @param {*} dataJson 是否对data进行json处理
 * @param {*} contentType application/x-www-form-urlencoded、application/json、 multipart/form-data
 * @returns
 */
let API = async <T = any>(url: string, type = 'GET', data: any, needAuth = true, dataJson = false, contentType = "application/json") => {
    type = type.toUpperCase();
    if (type === 'GET') {
        //数据拼接字符串
        let dataStr = dataTransform(data);
        if (dataStr !== '') {
            url = url + '?' + dataStr;
        }
    }
    let requestConfig: RequestInit = {
        method: type,
        headers: {
            'Accept': '*/*',
            'Content-Type': contentType,
        },
        mode: "cors",//请求的模式
        cache: "force-cache",
    }
    //读取本地token，没有则跳转登录
    const token = tokenGet();
    if (needAuth) {
        if (token) {
            requestConfig.headers = { ...requestConfig.headers, 'Authorization': token };
        } else {
            tokenRemove();
            throw new MineError("999999");
        }
    } else if (token) {
        requestConfig.headers = { ...requestConfig.headers, 'Authorization': token };
    }

    if (contentType === 'application/x-www-form-urlencoded' && data) {
        Object.defineProperty(requestConfig, 'body', {
            value: dataJson ? JSON.stringify(data) : dataTransform(data)
        })
    }
    const response = await fetch(url, requestConfig);
    let result = response.json();
    await result.then(res => {
        if (res && res.code === 999999) {
            localStorage.removeItem("token");
            throw new MineError("999999");
        }
    })
    return result;
}
/**
 * 对象格式化formdata
 * @param {*} data
        * @returns
        */
let dataTransform = (data: any) => {
    let dataStr = ''; //数据拼接字符串
    if (data) {
        Object.keys(data).forEach(key => {
            dataStr += key + '=' + data[key] + '&';
        })
        if (dataStr !== '') {
            dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'));
        }
    }
    return dataStr;
}
/**
 * 请求api——封装token
 * @param method 
 * @param url 
 * @param data 
 * @param header 
 * @returns 
 */
const requestX = async (method = 'GET', url: string, data?: any, header?: HeadersInit) => {
    //请求头
    let headers = new Headers({ 'Accept': '*/*', "Content-Type": 'application/json', ...header });
    //token
    if (localStorage.getItem("token")) {
        headers.set('Authorization', localStorage.getItem("token") + "");
    }
    //请求参数
    let requestConfig: RequestInit = {
        method: method,
        headers: headers,
        mode: "cors",//请求的模式
        cache: "force-cache",
    }
    if (method === 'GET') {
        //数据拼接字符串
        let dataStr = dataTransform(data);
        if (dataStr !== '') {
            url = url + '?' + dataStr;
        }
    } else if (headers.has('Content-Type') && data) {
        switch (headers.get('Content-Type')) {
            case 'application/x-www-form-urlencoded':
                requestConfig.body = dataTransform(data);
                break;
            case 'application/json':
                requestConfig.body = JSON.stringify(data);
                break;
            default:
                requestConfig.body = data;
                break;
        }
    }
    return fetch(url, requestConfig);
    /*let result = response.json();
    await result.then(res => {
        if (res && res.code === 999999) {
            localStorage.removeItem("token");
            throw new MineError("999999");
        }
    })
    return result;*/
}
/**
 * 请求api
 * @param method 
 * @param url 
 * @param data 
 * @param header 
 * @returns 
 */
const request = async (method = 'GET', url: string, data?: any, header?: HeadersInit) => {
    //请求头
    let headers = new Headers({ 'Accept': '*/*', "Content-Type": 'application/json', ...header });
    //请求参数
    let requestConfig: RequestInit = {
        method: method,
        headers: headers,
        mode: "cors",//请求的模式
        cache: "force-cache",
    }
    if (method === 'GET') {
        //数据拼接字符串
        let dataStr = dataTransform(data);
        if (dataStr !== '') {
            url = url + '?' + dataStr;
        }
    } else if (headers.has('Content-Type') && data) {
        switch (headers.get('Content-Type')) {
            case 'application/x-www-form-urlencoded':
                requestConfig.body = dataTransform(data);
                break;
            case 'application/json':
                requestConfig.body = JSON.stringify(data);
                break;
            default:
                requestConfig.body = data;
                break;
        }
    }
    return fetch(url, requestConfig);
}
export default API;
export { API, request, requestX, dataTransform };