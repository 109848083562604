import { Col, Table, Modal, Button, Form, Input, message, InputNumber, Space, Popconfirm } from "antd"
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import api from "src/services/api";
import { ADO } from "src/services/api/typings";

export default () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [data, setData] = useState<ADO.userBank[]>();
    const [tableParams, setTableParams] = useState({
        pagination: {
            page: 1,
            limit: 10,
        },
    });



    useEffect(() => {
        getList()
    }, [])

    const getList = () => {
        api.userBank.list({ ...tableParams.pagination }).then(res => {
            setTableParams({
                ...tableParams,
                pagination: {
                    page: res.current,
                    limit: res.size
                }
            })
            setData(res.records);
        });
    }

    const delBank = (id: number) => {
        api.userBank.del(id);
        getList();
    }

    const modalOK = () => {
        form.submit();
    }

    const onFinish = (value: any) => {
        api.userBank.put(value).then(res => {
            if (res.success) {
                message.success("添加成功");
                form.resetFields();
                setIsModalOpen(false);
                getList();
            }
        })

    }

    const columns: ColumnsType<ADO.userBank> = [
        {
            title: '银行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            render: (text) => {
                return text.substring(0, 16)
            }
        },
        {
            title: '支行名称',
            dataIndex: 'bankNameSub',
            key: 'bankNameSub',
            responsive: ['lg']
        },
        {
            title: '账户名',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: '卡号',
            dataIndex: 'accountNum',
            key: 'accountNum',
            responsive: ['lg']
        },
        {
            title: '添加时间',
            dataIndex: 'timeAdd',
            key: 'timeAdd',
            responsive: ['lg'],
            render: (text) => {
                return text.substring(0, 16)
            }
        }, {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            render: (text) => {
                return text === 1 ? '正常' : '异常'
            }
        }, {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        placement="topRight"
                        title="确认要删除吗？"
                        onConfirm={() => delBank(record.id)}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button danger size="small">删除</Button>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <>
            <Col span={24}>
                <Button onClick={() => setIsModalOpen(true)} type="primary">添加</Button>
            </Col>
            <Col span={24}>
                <Table dataSource={data} columns={columns} size="small" rowKey={(record) => record.id ? record.id : 0} />
                <Modal title="添加银行卡" open={isModalOpen} onOk={() => modalOK()} onCancel={() => { setIsModalOpen(false); form.resetFields(); }} okText="确认" cancelText="取消">
                    <Form
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="银行名称"
                            name="bankName"
                            rules={[{ required: true, message: '请输入银行名称!' }]}
                        >
                            <Input placeholder="请输入银行名称" />
                        </Form.Item>

                        <Form.Item
                            label="支行名称"
                            name="bankNameSub"
                        >
                            <Input placeholder="请输入支行名称" />
                        </Form.Item>
                        <Form.Item
                            label="账户名"
                            name="accountName"
                            rules={[{ required: true, message: '请输入银行名称!' }]}
                        >
                            <Input placeholder="请输入账户名" />
                        </Form.Item>
                        <Form.Item
                            label="卡号"
                            name="accountNum"
                            rules={[{ required: true, message: '请输入银行卡卡号!' }, { min: 15, max: 25, message: '请输入正确的银行卡卡号' }]}
                        >
                            <InputNumber placeholder="请输入银行卡卡号" controls={false} stringMode={true} style={{ width: '100%' }} />
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </>
    )
}