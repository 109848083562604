import { ADO } from "@/services/api/typings";
import modal from "antd/lib/modal";

function commErrorHandle(res: ADO.ResultMessage) {
    if (res.success) {
        return;
    }
    if (res.code === 10002) {
        modal.error({
            title: '失败'
            , content: (<p>抱歉，您还未实名认证!是否前往认证？</p>)
            , okText: '确定'
            , cancelText: '取消'
            , onOk: () => { window.location.href = "/user/system?initTab=2" }
        })
    } else {
        modal.error({
            title: '失败'
            , content: (<p>{res.message}</p>)
            , okText: '确定'
            , cancelText: '取消'
        })
    }
}

export { commErrorHandle }