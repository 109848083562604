import * as userCart from './UserCart';
import * as trade from './Trade';
import * as orderPublic from './OrderPublic';
import * as userCard from './UserCard';
import * as vouch from './Vouch';
import * as user from './User';
import * as userWalletBill from './UserWalletBill';
import * as userRecharge from './UserRecharge';
import * as userBank from './UserBank';
import * as statistic from './Statistic';
import * as brand from './Brand';
import * as contract from './Contract';
import * as employer from './Employer';
import * as website from './Website';
import * as websiteSell from './WebsiteSell';
import * as globalSetting from './GlobalSetting';
import * as news from './News';
import * as comm from './Comm';
import * as collect from './Collect';

export default {
    comm
    , contract
    , userCart
    , trade
    , orderPublic
    , userCard
    , vouch
    , user
    , userWalletBill
    , userRecharge
    , userBank
    , statistic
    , brand
    , employer
    , website
    , websiteSell
    , globalSetting
    , news
    , collect
}