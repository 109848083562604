import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";


/**
 * 
 * @param params 参数
 * @returns  提交订单
 */
export async function pay(data: ADO.orderPay): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/order", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultMessage;
            }
        })
}

/**
 * 
 * @param params 参数
 * @returns  提交订单
 */
export async function get(data: { page?: number, limit?: number, state?: number }): Promise<ADO.ResultPage<ADO.orderPublic>> {
    return requestX("GET", BASE_URL.url1 + "/user/order", data)
        .then(request => request.json()).then(res => {
            const list = res as unknown as ADO.ResultPage<ADO.orderPublic>;
            if (list.size) {
                return list;
            } else {
                return errorHandler(list) as ADO.ResultPage<ADO.orderPublic>;
            }
        })
}

/**
 * 
 * @param params 参数
 * @returns  提交订单
 */
export async function getByOrderNo(orderNo: string): Promise<ADO.ResultMessageT<ADO.orderPublic>> {
    return requestX("GET", BASE_URL.url1 + "/user/order/" + orderNo)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessageT<ADO.orderPublic>;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result) as ADO.ResultMessageT<ADO.orderPublic>;
            }
        })
}