import { BASE_URL } from "src/config/config";
import errorHandler from "src/requestErrorConfig";
import { requestX } from "src/utils/API";
import { ADO } from "./typings";
import { TablePaginationConfig } from 'antd/es/table';
/**
 * 
 * @param params 参数
 * @returns  提交认证
 */
export async function put(data: ADO.vouch): Promise<ADO.ResultMessage> {
    return requestX("PUT", BASE_URL.url1 + "/user/vouch", data, { 'Content-Type': 'application/x-www-form-urlencoded' })
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultMessage;
            if (result.success) {
                return result;
            } else {
                return errorHandler(result, '/vouch') as ADO.ResultMessage;
            }
        })
}

/**
 * 
 * @param params 参数
 * @returns  提交认证
 */
export async function list(data?: TablePaginationConfig): Promise<ADO.ResultPage<ADO.vouch>> {
    return requestX("GET", BASE_URL.url1 + "/user/vouch/list", data)
        .then(request => request.json()).then(res => {
            const result = res as unknown as ADO.ResultPage<ADO.vouch>;
            if (result.size) {
                return result;
            } else {
                return errorHandler(result, '/user/business/vouch') as ADO.ResultPage<ADO.vouch>;
            }
        })
}