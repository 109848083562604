import { Col, Form, Input, Button, Tabs, TabsProps, Upload, Image, Result, Typography } from "antd"
import api from "src/services/api";
import { message } from 'antd';
import { MessageInstance } from "antd/lib/message";
import { useEffect, useState } from "react";
import { BASE_URL } from "src/config/config";
import type { RcFile } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { userInfoGet } from "@/utils/token";
import { useSearchParams } from "react-router-dom";
import { getInfo } from "@/services/api/User";

const { Title, Paragraph } = Typography;


export default (props: { initTab?: string }) => {

    const [params] = useSearchParams();

    const [messageApi, contextHolder] = message.useMessage();
    const [activeKey, setActiveKey] = useState(props.initTab ? props.initTab : '1');

    useEffect(() => {
        const initTab = params.get('initTab');
        if (initTab) {
            setActiveKey(initTab);
        }
    }, [])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `基础信息`,
            children: <UserInfo messageApi={messageApi} />,
        },
        {
            key: '2',
            label: `实名认证`,
            children: <UserCard />,
        }
    ];

    return (
        <Col span={24}>
            {contextHolder}
            <Tabs activeKey={activeKey} items={items} onChange={(key) => setActiveKey(key)} />
        </Col>
    )

}

const UserInfo = (props: { messageApi: MessageInstance }) => {

    const messageApi = props.messageApi;
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        api.user.update(values).then(res => {
            if (res.success) {
                messageApi.success('更新成功！');
                getInfo().then(res => {
                    if (res.success) {
                        form.setFieldsValue(res.data);
                    }
                })
            } else {
                messageApi.error('网络异常，更新失败，请稍后重试！');
            }
        })
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 4 }}
            style={{ maxWidth: 600 }}
            initialValues={userInfoGet()}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
        >
            <Form.Item
                label="用户名"
                name="name"
            >
                <Input disabled={true} />
            </Form.Item>
            <Form.Item
                label="手机号码"
                name="tel"
            >
                <Input disabled={userInfoGet()?.tel ? true : false} />
            </Form.Item>
            <Form.Item
                label="QQ"
                name="qq"
                rules={[{ pattern: /[1-9][0-9]{4,}/, message: '请输入正确的QQ号码' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="微信"
                name="wx"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="email"
                rules={[{ type: 'email', message: '请输入正确的邮箱地址' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ xs: { span: 24 }, lg: { offset: 4, span: 16 } }}>
                <Button type="primary" htmlType="submit">
                    确认
                </Button>
            </Form.Item>
        </Form>
    )
}

const UserCard = (props: any) => {

    //实名认证初始状态：0默认，实名认证，1存在认证待审核 2认证成功
    const [accountState, setAccountState] = useState<number>();

    useEffect(() => {
        const userInfo = userInfoGet();
        if (userInfo?.state && userInfo.state === 2) {
            setAccountState(2);
        } else {
            api.userCard.get().then(res => {
                if (res.data) {
                    setAccountState(1);
                } else {
                    setAccountState(0);
                }
            })
        }
    }, [])

    return (
        <>
            {accountState === 0 && <UserCardAdd setAccountState={setAccountState} />}
            {accountState === 1 && <Result status="warning" title="认证信息审核中，请耐心等待！" />}
            {accountState === 2 &&
                <Result
                    status="success"
                    title="您已认证成功！"
                    extra={[
                        <Button type="primary" href='/user' key="user">
                            个人中心
                        </Button>,
                        <Button href='/' key="index">首&emsp;&emsp;页</Button>,
                    ]}
                />
            }

        </>
    )
}

const UserCardAdd = (props: { setAccountState: any }) => {
    const [accountImgDown, setAccountImgDown] = useState<string>();
    const [accountImgUp, setAccountImgUp] = useState<string>();

    const onFinish = (values: any) => {
        let data = { ...values, imgUp: values.imgUp[0].response.data, imgDown: values.imgDown[0].response.data };
        api.userCard.put(data).then(res => {
            if (res.success) {
                props.setAccountState(1);
            }
        });
    };

    const onFinishFailed = (errorInfo: any) => {
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('文件格式不正确，只能上传JPG/PNG格式。');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片必须小于2MB。');
        }
        return isJpgOrPng && isLt2M;
    };

    //将图片转换为base64，仅供显示，不是服务器的响应数据
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const accountUploadChange = (info: UploadChangeParam<UploadFile>, type: string) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                if (type === 'up') {
                    setAccountImgUp(url);
                } else {
                    setAccountImgDown(url);
                }
            });
        }
    };

    //上传组件参数
    const uploadProps = {
        name: 'img'
        , headers: { 'Authorization': localStorage.getItem("token") + "" }
        , accept: 'image'
        , maxCount: 1
        , showUploadList: false
        , data: { t: 4 }
        , action: BASE_URL.url1 + "/user/upload/img"
        , beforeUpload: beforeUpload
    }

    return (
        <Col span={24}>
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="真实姓名"
                    name="name"
                    rules={[{ required: true, message: '姓名不能为空!' }]}
                >
                    <Input placeholder='请输入真实姓名' />
                </Form.Item>
                <Form.Item
                    label="身份证号"
                    name="num"
                    rules={[{ required: true, message: '身份证号不能为空!' }, { type: 'string', len: 18, message: '身份证号码不正确!' }]}
                >
                    <Input placeholder='请输入身份证号' />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 10 }} label="国徽面" name="imgUp" valuePropName="fileList" getValueFromEvent={normFile}
                    rules={[{ required: true, message: '请上传国徽面图片!' }]}>
                    <Upload onChange={(info: UploadChangeParam<UploadFile>) => accountUploadChange(info, "up")}  {...uploadProps}>
                        {accountImgUp ? <img src={accountImgUp} alt="avatar" style={{ width: '100%' }} /> : <Image src={BASE_URL.img2 + 'account/img1.png'} preview={false} height={120} style={{ border: '1px dashed #ccccd8' }} />}
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 10 }} label="信息面" name="imgDown" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: '请上传信息面图片!' }]}>
                    <Upload onChange={(info: UploadChangeParam<UploadFile>) => accountUploadChange(info, "down")} {...uploadProps}>
                        {accountImgDown ? <img src={accountImgDown} alt="avatar" style={{ width: '100%' }} /> : <Image src={BASE_URL.img2 + 'account/img2.png'} preview={false} height={120} style={{ border: '1px dashed #ccccd8' }} />}
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ xs: { span: 24 }, lg: { offset: 4, span: 16 } }}>
                    <Button type="primary" htmlType="submit"> 提交 </Button>
                </Form.Item>
            </Form>
            <Typography>
                <Title level={5}>注意事项：</Title>
                <Paragraph>
                    1、每个证件只能绑定一个账号
                </Paragraph>
                <Paragraph>
                    2、证件照不清晰或与输入的信息不匹配,将导致实名认证被驳回
                </Paragraph>
                <Paragraph>3、您提供的证件信息将受到严格保护，仅用于身份验证,未经本人许可不会用于其他用途</Paragraph>
                <Paragraph>4、除原证件无效（如：改名、移民）等特殊情况外，实名认证审核通过后将不能修改</Paragraph>
                <Title level={5}>照片要求</Title>
                <Paragraph>1、证件照上信息需完整且清晰可辨（无反光、遮挡、水印、证件套、logo等）</Paragraph>
                <Paragraph>2、申请人填写的“真实姓名”和“证件号码”需和提交证件照片信息一致</Paragraph>
                <Paragraph>3、证件必须真实拍摄，不能使用复印件</Paragraph>
                <Paragraph>4、确保照片完整（不缺边角），证件周围不允许加上边角框（如：加上红框等）</Paragraph>
            </Typography>
        </Col>
    )

}