import { Col, Row, Steps, Input, Select, Button, Form, Modal, InputRef } from 'antd';
import React, { useRef, useState } from 'react';
import './vouch.less';
import api from 'src/services/api';
import { ADO } from 'src/services/api/typings';
import { commErrorHandle } from '@/utils';

const { Option } = Select;

const Vouch: React.FC = () => {

    const [form] = Form.useForm();
    const inpVouchRef = useRef<InputRef>(null);

    const [vouch, setVouch] = useState({ visible: false });
    const [modal, contextHolder] = Modal.useModal();
    const [openForm, setOpenForm] = useState(false);

    const onCreate = (values: any) => {
        subVouchForm(values);
        setOpenForm(false);
    };
    const subVouchForm = (values: ADO.vouch) => {
        api.vouch.put({ ...values }).then(res => {
            if (res.success) {
                modal.success({
                    title: '成功'
                    , content: (<p>提交成功，平台将在一个至三个工作日内与您联系，请保持手机信号畅通！</p>)
                    , okText: '确定'
                })
            } else {
                commErrorHandle(res);
            }
        })
    }
    const submitVouch = (values: ADO.vouch) => {
        if (!values.title) {
            setVouch({ ...vouch, visible: true });
        } else {
            setVouch({ ...vouch, visible: false });
            subVouchForm(values);
        }
    }

    return (
        <>
            {/* 首屏背景 */}
            <Row className='vouch-top-box' justify="center">
                <Col xxl={16} xs={24} className="vouch-top-box-title">
                    <h2>创易鸟担保交易中心</h2>
                    <p>担保交易服务费低至<span>2%</span></p>
                </Col>
                <Col lg={0} xs={24}>
                    <Button type="link" href="http://wpa.qq.com/msgrd?v=3&uin=1134170330&site=qq&menu=yes" className='vouch-top-box-button' rel="nofollow" block target={'_blank'}>立即发起中介</Button>
                </Col>
                <Col lg={24} xxl={16} xs={0} className='vouch-top-box-form ctn-box'>
                    <Form form={form} name="user_vouch" onFinish={submitVouch}>
                        <Form.Item>
                            <Input.Group compact size='large'>
                                <Form.Item noStyle>
                                    <Input
                                        style={{
                                            width: '15%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="我要出售"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item noStyle name="vouchType" initialValue={1}>
                                    <Select style={{ width: '10%' }} size='large'>
                                        <Option value={1}>域名</Option>
                                        <Option value={2}>网站</Option>
                                        <Option value={3}>自媒体账号</Option>
                                        <Option value={99}>其他</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item noStyle name="title" rules={[{ required: true, message: "请输入担保内容!" }]}>
                                    <Input placeholder="请输入担保内容" style={{ width: '60%' }} autoComplete="off" ref={inpVouchRef} />
                                </Form.Item>
                                <Form.Item noStyle>
                                    <Button type="primary" htmlType="submit" style={{ width: '15%' }} size='large'>发起中介</Button>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {/* 担保内容 */}
            <Row className="vouch-box" justify='center'>
                <Col xxl={16} xs={24} className="nav-title">
                    <h2>担保内容</h2>
                    <p>GUARANTEE CONTENT</p>
                </Col>
                <Col xxl={16} xs={24} className="vouch-box-content">
                    <Row>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v1.png" />
                            <h2 className='common-elip'>网站、域名、APP</h2>
                            <p className='common-elip-3'>依托优质互联网创业圈资源，在网站、域名、app等互联网资产中介中交易额持续增长，让线上交易更安全靠谱，线上中介就上创易鸟担保中介。</p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v2.png" />
                            <h2>普通商品</h2>
                            <p className='common-elip-3'>走创易鸟担保中介，您可以从任何地方购物，不要担心代购资金问题。</p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v3.png" />
                            <h2>安全高效</h2>
                            <p className='common-elip-3'>自助创建订单，全程便捷高效，提现资金及时到账！</p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v4.png" />
                            <h2>自媒体账号</h2>
                            <p className='common-elip-3'>自媒体账号包含各个平台的自媒体账号，比如微信公众号、今日头条、一点资讯、搜狐、百度百家、网易、UC大鱼号等各种自媒体账号皆可。</p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v5.png" />
                            <h2>服务协议</h2>
                            <p className='common-elip-3'>创易鸟提供任何线上虚拟资产的中介，比如服务协议、技术服务、游戏账号、流量业务等线上资产。</p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <img src="/img/vouch/v6.png" />
                            <h2>分期交易</h2>
                            <p className='common-elip-3'>双方都接受分期交易时，买方分期支付可以缓解支付交易款项的压力，分期打款到卖家也能保证物品或服务的质量问题。</p>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={16}>
                    <input type="button" className="vouch-box-btn" value="立即委托"
                        // onClick={() => inpVouchRef.current!.focus({ cursor: 'end', })}
                        onClick={() => setOpenForm(true)}
                    />
                </Col>
            </Row>
            {/* 交易流程 */}
            <Row className="vouch-process-box" justify='center'>
                <Col className="nav-title" xxl={16} xs={24}>
                    <h2>交易流程</h2>
                    <p>TRANSACTION PROCESS</p>
                </Col>
                <Col xxl={16} xs={24}>
                    <Steps
                        labelPlacement="vertical" status="process"
                        items={[
                            {
                                title: '挑选商品',
                                description: "买方选中物品或服务，并支付款项"
                            },
                            {
                                title: '核实信息',
                                description: "平台协助核实物品和服务信息"
                            }, {
                                title: '交接物品或服务',
                                description: "买卖双方开始交接物品或服务"
                            }, {
                                title: '买方确认',
                                description: "买方确认物品或服务"
                            }, {
                                title: '交易完成',
                            }
                        ]}
                    />
                </Col>
            </Row>
            {/* 最新委托 */}
            <Row className="vouch-order" justify='center'>
                <Col className="nav-title" xxl={16} xs={24}>
                    <h2>最新委托</h2>
                    <p>LATEST COMMISSION</p>
                </Col>
                <Col className="vouch-order-item" xxl={16} xs={24}>
                    <Row>
                        <Col lg={2} xs={24}>
                            <span>域名交易</span>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={2} xs={6}>
                            <p>03-19</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="vouch-order-item" xxl={16} xs={24}>
                    <Row>
                        <Col lg={2} xs={24}>
                            <span>网站交易</span>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>www.chuangyiniao.cn</p>
                        </Col>
                        <Col lg={2} xs={6}>
                            <p>03-19</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="vouch-order-item" xxl={16} xs={24}>
                    <Row>
                        <Col lg={2} xs={24}>
                            <span>自媒体交易</span>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>知乎号交易账号XXXXXXX…</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>熊掌号交易交易交易……</p>
                        </Col>
                        <Col lg={4} xs={6}>
                            <p>03-19</p>
                        </Col>
                        <Col lg={4} xs={18}>
                            <p>创易鸟交易交易交易</p>
                        </Col>
                        <Col lg={2} xs={6}>
                            <p>03-19</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {contextHolder}
            <VouchForm
                open={openForm}
                onOk={onCreate}
                onCancel={() => {
                    setOpenForm(false);
                }}
            />
        </>
    );
}

interface VouchFormProps {
    open: boolean;
    onOk: (values: ADO.vouch) => void;
    onCancel: () => void;
}
const VouchForm: React.FC<VouchFormProps> = ({
    open,
    onOk,
    onCancel,
}) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={open}
            title="申请担保"
            okText="提交"
            cancelText="取消"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onOk(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                name="form_in_modal"
                initialValues={{ modifier: 'public' }}
            >
                <Form.Item name="vouchType" label="担保类型" initialValue={1} rules={[{ required: true }]}>
                    <Select>
                        <Option value={1}>域名</Option>
                        <Option value={2}>网站</Option>
                        <Option value={3}>自媒体账号</Option>
                        <Option value={99}>其他</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="title" label="担保内容" rules={[{ required: true, message: "请输入担保内容!" }]}>
                    <Input.TextArea placeholder="请输入担保内容" autoComplete="off" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Vouch;